import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBell,
  faCog,
  faEnvelopeOpen,
  faSearch,
  faSignOutAlt,
  faUserShield,
} from '@fortawesome/free-solid-svg-icons';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import {
  Row,
  Col,
  Nav,
  Form,
  Image,
  Navbar,
  Dropdown,
  Container,
  ListGroup,
  InputGroup,
  Button,
} from 'react-bootstrap';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { PageTitle } from '../PageTitle';
import { axiosGet } from '../../helpers/RequestHelper';
import { useLocation, useNavigate } from 'react-router-dom';
import { isAuthenticated } from 'react-auth-kit/utils/utils';
import { HomelyIcon } from '../../helpers/Utils';
import envelopeIcon from '../../../assets/icons/hmicons/envelopeIcon.svg';
import bellIcon from '../../../assets/icons/hmicons/bellIcon.svg';
interface NotificationProps {
  id: string;
  link: string;
  sender: string;
  image: string;
  time: string;
  message: string;
  read?: boolean;
}

const Notification = ({
  link,
  sender,
  image,
  time,
  message,
  read = false,
}: NotificationProps) => {
  const readClassName = read ? '' : 'text-danger';

  return (
    <ListGroup.Item action href={link} className="border-bottom border-light">
      <Row className="align-items-center">
        <Col className="col-auto">
          <Image src={image} className="user-avatar lg-avatar rounded-circle" />
        </Col>
        <Col className="ps-0 ms--2">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h4 className="h6 mb-0 text-small">{sender}</h4>
            </div>
            <div className="text-end">
              <small className={readClassName}>{time}</small>
            </div>
          </div>
          <p className="font-small mt-1 mb-0">{message}</p>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

const NavbarComponent = (props: any) => {
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);
  const areNotificationsRead = true;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const signOut = useSignOut();

  const markNotificationsAsRead = () => {
    setNotifications(notifications.map((n) => ({ ...n, read: true })));
  };

  const user: any = useAuthUser();

  useEffect(() => {
    const checkUserProfile = async () => {
      try {
        const authUser = await axiosGet('/account/profile');
        if (!authUser?.success) {
          console.log('false user');
          signOut();
          navigate('/auth/login');
        }
      } catch (error) {
        console.error('Error checking user profile:', error);
        signOut();
        navigate('/auth/login');
      }
    };

    checkUserProfile();
  }, [navigate]);

  return (
    <Navbar
      variant="white"
      expanded
      className="ps-0 pe-2 pb-0 bg-white"
      style={props.style}
    >
      <Container fluid className="px-3">
        <div className="d-flex justify-content-between w-100">
          <PageTitle />

          <Nav className="align-items-center">
            <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead}>
              <Dropdown.Toggle
                as={Nav.Link}
                className="text-dark icon-notifications me-lg-3"
              >
                <span className="icon icon-sm">
                  <div className="d-flex">
                    <HomelyIcon src={bellIcon} size={34} />
                    <HomelyIcon src={envelopeIcon} size={34} />
                  </div>
                  {areNotificationsRead ? null : (
                    <span className="icon-badge rounded-circle unread-notifications" />
                  )}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Nav.Link
                    href="#"
                    className="text-center text-primary fw-bold border-bottom border-light py-3"
                  >
                    Notifications
                  </Nav.Link>

                  {notifications.map((n: NotificationProps) => (
                    <Notification key={`notification-${n.id}`} {...n} />
                  ))}

                  <Dropdown.Item className="text-center text-primary fw-bold py-3">
                    View all
                  </Dropdown.Item>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Image
                    src={user?.picture ?? '/assets/img/team/blank.webp'}
                    className="user-avatar md-avatar rounded-circle"
                  />
                  <div className="media-body ms-2 text-dark align-items-start d-none d-lg-block d-lg-flex flex-lg-column ">
                    <span className="mb-0 text-left font-small fw-bold">
                      {user?.firstname && user?.lastname
                        ? `${user.firstname} ${user.lastname}`
                        : 'Admin'}
                    </span>
                    <span className="text-muted">
                      {user?.role == 1 ? 'Super Admin' : 'Admin'}
                    </span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My
                  Profile
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faCog} className="me-2" /> Settings
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item
                  className="fw-bold"
                  as={Button}
                  onClick={() => {
                    signOut();
                    navigate('/auth/login');
                  }}
                >
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    className="text-danger me-2"
                  />{' '}
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
