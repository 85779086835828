import axios, { InternalAxiosRequestConfig } from 'axios';
import { MySwal, Notify } from './NotificationHelper';

axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT;
axios.defaults.headers.Accept = 'application/json';

export function getCookie(cname: any) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

axios.interceptors.request.use(
  (config: InternalAxiosRequestConfig<any>) => {
    const auth = getCookie('_auth');

    if (auth) {
      config.headers.Authorization = `Bearer ${auth}`;
    }

    return config;
  },
  (err: any) => Promise.reject(err)
);

axios.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: any) {
    return Promise.resolve(error.response);
  }
);

export const axiosPost = async (path: string, data: any) => {
  if (navigator.onLine) {
    const { data: result } = await axios.post(path, data);

    if (result?.code !== 200 && !result?.success) {
      Notify({
        type: 'toastr',
        toastrProps: {
          text: result?.message,
          type: 'error',
        },
      });
    } else {
      Notify({
        type: 'toastr',
        toastrProps: {
          text: result?.message,
          type: 'success',
        },
      });
    }
    return result;
  } else {
    Notify({
      type: 'toastr',
      toastrProps: {
        text: 'Internet connection required.',
        type: 'error',
      },
    });
  }
};
export const axiosPut = async (path: string, data: any) => {
  if (navigator.onLine) {
    const { data: result } = await axios.put(path, data);

    console.log(result);
    if (result?.code !== 200 && !result?.success) {
      Notify({
        type: 'toastr',
        toastrProps: {
          text: result?.message,
          type: 'error',
        },
      });
    } else {
      Notify({
        type: 'toastr',
        toastrProps: {
          text: result?.message,
          type: 'success',
        },
      });
    }
    return result;
  } else {
    Notify({
      type: 'toastr',
      toastrProps: {
        text: 'Internet connection required.',
        type: 'error',
      },
    });
  }
};

export const axiosGet = async (path: string) => {
  const { data: req } = await axios.get(path);
  if (req?.code !== 200 && !req?.success) {
    Notify({
      type: 'toastr',
      toastrProps: {
        text: req?.message,
        type: 'error',
      },
    });
  }

  return req;
};
export const axiosDelete = async (
  path: string,
  title = 'Are you sure?',
  text = "You won't be able to revert this!"
) => {
  try {
    const request = await MySwal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: `#010038`,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete',
    });

    if (request.isConfirmed) {
      const { data: delReq } = await axios.delete(path);

      if (!delReq?.success && delReq.status !== 200) {
        MySwal.fire('Error Occured!', delReq.message, 'error');
      } else {
        MySwal.fire('Deleted!', delReq.message, 'success');
      }
      return delReq;
    }
    return false;
  } catch (error) {
    Notify({
      type: `toastr`,
      toastrProps: {
        text: error,
        type: `error`,
      },
    });
  }
};
export const axiosSpecial: ({
  path,
  title,
  text,
  type,
  data,
}: {
  path: any;
  title?: any;
  text?: any;
  type?: any;
  data?: any;
}) => Promise<any> = async ({
  path,
  title = 'Are you sure?',
  text = "You won't be able to revert this!",
  type,
  data,
}): Promise<any> => {
  try {
    const request = await MySwal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: `#010038`,
      cancelButtonColor: '#d33',
      confirmButtonText: `Yes, ${type}`,
    });

    if (request.isConfirmed) {
      const { data: delReq } = await axios.put(`${path}`, data);

      if (!delReq.isSuccess && delReq.status !== 200) {
        MySwal.fire('Error Occured!', delReq.message, 'error');
      } else {
        MySwal.fire(`${type}d`, delReq.message, 'success');
      }
      return delReq;
    }
    return false;
  } catch (error) {
    Notify({
      type: `toastr`,
      toastrProps: {
        text: error,
        type: `error`,
      },
    });
  }
};
