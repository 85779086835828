import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCashRegister,
  faChartLine,
  faCloudUploadAlt,
  faPlus,
  faRocket,
  faTasks,
  faUserShield,
} from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup, Col, Dropdown, Row } from 'react-bootstrap';
import {
  SalesValueWidgetPhone,
  CounterWidget,
  BarChartWidget,
  TeamMembersWidget,
  CircleChartWidget,
  SalesValueWidget,
  ProgressTrackWidget,
  AcquisitionWidget,
  RankingWidget,
} from '../../modules/widgets/Widgets';
import { totalOrders, trafficShares } from '../../modules/data/charts';
import { PageVisitsTable } from '../../modules/widgets/Tables';

export default () => {
  return (
    <Fragment>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <ButtonGroup>
          <Button variant="outline-primary" size="sm">
            Share
          </Button>
          <Button variant="outline-primary" size="sm">
            Export
          </Button>
        </ButtonGroup>
      </div>

      <Row className="justify-content-md-center">
        <Col xs={12} className="mb-4 d-none d-sm-block">
          <h4>Home</h4>
          {/*<SalesValueWidget*/}
          {/*  title="Sales Value"*/}
          {/*  value="10,567"*/}
          {/*  percentage={10.57}*/}
          {/*/>*/}
        </Col>
        <Col xs={12} className="mb-4 d-sm-none">
          {/*<SalesValueWidgetPhone*/}
          {/*  title="Sales Value"*/}
          {/*  value="10,567"*/}
          {/*  percentage={10.57}*/}
          {/*/>*/}
        </Col>
        <Col xs={12} sm={6} xl={4} className="mb-4">
          {/*<CounterWidget*/}
          {/*  category="Customers"*/}
          {/*  title="345k"*/}
          {/*  period="Feb 1 - Apr 1"*/}
          {/*  percentage={18.2}*/}
          {/*  icon={faChartLine}*/}
          {/*  iconColor="shape-secondary"*/}
          {/*/>*/}
        </Col>

        <Col xs={12} sm={6} xl={4} className="mb-4">
          {/*<CounterWidget*/}
          {/*  category="Revenue"*/}
          {/*  title="$43,594"*/}
          {/*  period="Feb 1 - Apr 1"*/}
          {/*  percentage={28.4}*/}
          {/*  icon={faCashRegister}*/}
          {/*  iconColor="shape-tertiary"*/}
          {/*/>*/}
        </Col>

        <Col xs={12} sm={6} xl={4} className="mb-4">
          {/*<CircleChartWidget title="Traffic Share" data={trafficShares} />*/}
        </Col>
      </Row>

      <Row>
        <Col xs={12} xl={12} className="mb-4">
          <Row>
            <Col xs={12} xl={8} className="mb-4">
              <Row>
                <Col xs={12} className="mb-4">
                  {/*<PageVisitsTable />*/}
                </Col>

                <Col xs={12} lg={6} className="mb-4">
                  {/*<TeamMembersWidget />*/}
                </Col>

                <Col xs={12} lg={6} className="mb-4">
                  {/*<ProgressTrackWidget />*/}
                </Col>
              </Row>
            </Col>

            <Col xs={12} xl={4}>
              <Row>
                <Col xs={12} className="mb-4">
                  {/*<BarChartWidget*/}
                  {/*  title="Total orders"*/}
                  {/*  value={452}*/}
                  {/*  percentage={18.2}*/}
                  {/*  data={totalOrders}*/}
                  {/*/>*/}
                </Col>

                <Col xs={12} className="px-0 mb-4">
                  {/*<RankingWidget />*/}
                </Col>

                <Col xs={12} className="px-0">
                  {/*<AcquisitionWidget />*/}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};
