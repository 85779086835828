import { Fragment } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { PropertyList } from './components/PropertyList';
import NotFound from '../NotFound';
import { PropertyNav } from './PropertyNav';
import { NewProperty } from './components/NewProperty';
import { EditProperty } from './components/EditProperty';
import { CategoryList } from './components/category/CategoryList';
import { AmenityList } from './components/amenity/AmenityList';
import { LocationList } from './components/Location/LocationList';
import { ViewProperty } from './components/view/ViewProperty';
import { ViewPage } from './components/view/ViewPage';
import { ViewNav } from './components/view/ViewNav';

const PropertyPage = () => {
  return (
    <Fragment>
      <Routes>
        <Route
          element={
            <Fragment>
              <PropertyNav />
              <Outlet />
            </Fragment>
          }
        >
          <Route index path="all" element={<PropertyList />} />
          <Route path="new" element={<NewProperty />} />
          <Route path="view/*" element={<ViewPage />} />
          <Route path="edit/:id" element={<EditProperty />} />
          <Route path="category" element={<CategoryList />} />
          <Route path="amenity" element={<AmenityList />} />
          <Route path="places" element={<LocationList />} />
          <Route index element={<Navigate to="/listing/all" />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Fragment>
  );
};
export { PropertyPage };
