import { FC, Fragment, useEffect, useState } from 'react';
import { CloseButton, Col, Modal, ModalProps, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { FormButton } from '../../FormButton';
import { useFormik } from 'formik';
import { number, object, string } from 'yup';
import { axiosPost, axiosPut } from '../../../helpers/RequestHelper';

interface Reservation {
  id?: number;
  name: string;
  status: number;
  description: string;
}

interface ReservationModalProps extends ModalProps {
  data?: Reservation | null;
  onHide?: () => void;
  reload?: (e: boolean) => void;
}

const ReservationSourceModal: FC<ReservationModalProps> = ({
  show,
  onHide = () => {},
  data,
  reload,
}) => {
  const [loading, setLoading] = useState(false);
  const initialValues = {
    name: data?.name || '',
    status: data?.status || 0,
    description: data?.description || '',
  };

  const validationSchema = object({
    name: string().required('Name is required.'),
    status: number().required('Status is required.'),
    description: string().required('Description is required.'),
  });

  const {
    getFieldProps,
    isSubmitting,
    touched,
    errors,
    handleSubmit,
    isValid,
    values,
    setValues,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      try {
        let req;
        if (data?.id) {
          req = await axiosPut(`/reservation/source/list/${data.id}`, {
            name: values.name,
            status: values.status,
            description: values.description,
          });
        } else {
          req = await axiosPost('/reservation/source/create', {
            name: values.name,
            status: values.status,
            description: values.description,
          });
        }
        if (req?.success) {
          resetForm();
          if (reload) {
            reload(true);
          }
          if (onHide) {
            onHide();
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (data) {
      setValues({
        name: data.name,
        status: data.status,
        description: data.description,
      });
    }
  }, [data, setValues]);

  return (
    <Fragment>
      <Modal size="lg" centered show={show} onHide={onHide}>
        <Modal.Header className="d-flex align-baseline">
          <h4>
            {data ? `Edit - ${values?.name}` : `Create - ${values?.name}`}
          </h4>
          <CloseButton onClick={onHide} />
        </Modal.Header>
        <Modal.Body className="p-3">
          <form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={!!errors.name}
                    placeholder="Enter name"
                    {...getFieldProps('name')}
                    name="name"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    {...getFieldProps('status')}
                    name="status"
                    isInvalid={!!errors.status}
                  >
                    <option>Select</option>
                    <option value={1}>Active</option>
                    <option value={0}>Inactive</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.status}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={12}>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    {...getFieldProps('description')}
                    name="description"
                    isInvalid={!!errors.description}
                    rows={6}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={4}>
                <FormButton
                  type="submit"
                  isSubmitting={isSubmitting}
                  isValid={isValid}
                  text={data ? 'Save Changes' : 'Create Reservation'}
                />
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export { ReservationSourceModal };
