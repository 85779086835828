import React, { Fragment } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const ViewReviews = () => {
  return (
    <Fragment>
      <Row>
        <Col md={8}>
          <Card>
            <Card.Header>
              <h3>Reviews</h3>
              <small className="text-muted">
                Read and manage property reviews from guests.
              </small>
            </Card.Header>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export { ViewReviews };
