import { ReservationIntials, ReservationModel } from './reservation.interface';

export type propertyArrayDataProps = {
  category: any[];
  city: any[];
  amenity: any[];
};

export type PropertyModel = {
  id: string;
  name: string;
  internal_name: string;
  description: string;
  slug: string;
  average_rating: number;
  available: number;
  availability_date: string;
  status: number;
  created: string;
  updated: string;
  location: LocationModel;
  pictures: PictureModel[];
  fee: FeeModel;
  reviews: ReviewModel[];
  reservation: ReservationModel[];
};

export type LocationModel = {
  id: string;
  address: string;
  address_two: string;
  apartment_number: string;
  zipcode: number;
  longitude: string;
  latitude: string;
  created: string;
  updated: string;
  place: PlaceModel;
};

export type PlaceModel = {
  id: string;
  city: string;
  neighborhood: string | null;
  status: number;
  created: string;
  updated: string;
};

export type PictureModel = {
  id: string;
  name: string;
  token: string;
  type: number;
  front: boolean;
  status: number;
  created: string;
  updated: string;
};

export type FeeModel = {
  id: string;
  service_charge: number;
  caution_charge: number;
  price: number;
  created: string;
  updated: string;
};

export type ReviewModel = {
  // Adjust fields if review objects are detailed elsewhere
};

export const PropertyIntials: PropertyModel = {
  id: '',
  name: '',
  internal_name: '',
  description: '',
  slug: '',
  average_rating: 0,
  available: 0,
  availability_date: '',
  status: 0,
  created: '',
  updated: '',
  location: {
    id: '',
    address: '',
    address_two: '',
    apartment_number: '',
    zipcode: 0,
    longitude: '',
    latitude: '',
    created: '',
    updated: '',
    place: {
      id: '',
      city: '',
      neighborhood: null,
      status: 0,
      created: '',
      updated: '',
    },
  },
  pictures: [], // Initialize as an empty array
  fee: {
    id: '',
    service_charge: 0,
    caution_charge: 0,
    price: 0,
    created: '',
    updated: '',
  },
  reviews: [], // Initialize as an empty array
  reservation: [ReservationIntials], // Initialize as an empty array
};
