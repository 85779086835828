import React, { Fragment, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Table } from "../../../modules/widgets/table/Table";
import { SourceColumn } from "../../../modules/widgets/table/core/Columns";
import { axiosGet } from "../../../modules/helpers/RequestHelper";
import { ReservationSourceModal } from "../../../modules/widgets/modals/reservation/ReservationSourceModal";

const ReservationSource = () => {
  const [data, setData] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<any>(null);

  const fetchData = async () => {
    try {
      const req = await axiosGet("/reservation/source/list");
      console.log(req);
      if (req?.success) {
        const { source } = req;
        setData(source);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (reload) {
      fetchData();
    } else {
      fetchData();
    }
  }, [reload]);

  console.log(reload);
  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedData(null);
  };

  return (
    <Fragment>
      <div className="d-flex gap-4">
        <ReservationSourceModal
          show={openModal}
          onHide={handleModalClose}
          reload={(isReload) => setReload(isReload)}
          data={selectedData}
        />
      </div>

      {/*<div className="d-flex justify-content-end my-3">*/}
      {/*  <Button*/}
      {/*    onClick={() => {*/}
      {/*      setSelectedData(null);*/}
      {/*      setOpenModal(true);*/}
      {/*    }}*/}
      {/*    variant="primary"*/}
      {/*  >*/}
      {/*    Create Source*/}
      {/*  </Button>*/}
      {/*</div>*/}

      {/* Display content if not loading and no error */}
      <Table columns={SourceColumn} data={data} />
    </Fragment>
  );
};

export { ReservationSource };
