import React, {
  createContext,
  useState,
  useContext,
  FC,
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';

// Define the shape of the context value
interface PageContextType {
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  layoutType: string;
  setLayoutType: React.Dispatch<React.SetStateAction<string>>;
}

// Define the context with a default value of `null`
const PageContext = createContext<PageContextType | null>(null);

// Custom hook to access the Page context
export const usePageContext = () => {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error('usePageContext must be used within a PageProvider');
  }
  return context;
};

export const PageProvider: FC<any> = ({ children }) => {
  const [title, setTitle] = useState('Dashboard'); // Default title
  const [layoutType, setLayoutType] = useState('regular'); // Default layout type

  const location = useLocation();

  return (
    <PageContext.Provider
      value={{ title, setTitle, layoutType, setLayoutType }}
    >
      {children}
    </PageContext.Provider>
  );
};
