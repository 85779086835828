import React, { FC, Fragment, useEffect, useState } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import { clsx } from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosGet } from '../../../../modules/helpers/RequestHelper';
import { SectionLoader } from '../../../../modules/helpers/Utils';

const ViewAll = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true); // State for loading indicator
  const [error, setError] = useState<boolean>(false); // State for error handling
  const [errorMessage, setErrorMessage] = useState<string>(''); // Error message state
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const property = await axiosGet(`/property/list/${id}`);
        if (property?.success) {
          setData(property?.property);
        }
      } catch (err: any) {
        setError(true); // Set error state to true
        setErrorMessage(
          err.message || 'An error occurred while fetching data.'
        ); // Set error message
      } finally {
        setLoading(false); // Set loading state to false regardless of success or failure
      }
    })();
  }, []);

  return (
    <Fragment>
      {/* Display error message if error */}
      {/* Display content if not loading and no error */}

      <Row>
        <Col md={8}>
          <Card>
            <Card.Header>
              <h3>Amenities</h3>
              <small className="text-muted">
                General amenities attached to property.
              </small>
            </Card.Header>

            <Card.Body>
              {/* Display loading spinner if loading */}
              {loading && <SectionLoader success={false} error={false} />}
              {error && (
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              )}
              {!loading && !error && (
                <Row>
                  {data?.amenity?.length > 0 &&
                    data?.amenity.map(
                      ({ accepted_amentity: { name, slug, id } }: any) => (
                        <Col sm={4} key={id}>
                          <ListGroup className="text-nowrap">
                            <ListGroup.Item>
                              {slug ? (
                                <i className={clsx(slug, 'me-2')}></i>
                              ) : (
                                <FontAwesomeIcon
                                  icon={faExclamation}
                                  className="me-2"
                                />
                              )}
                              {name}
                            </ListGroup.Item>
                          </ListGroup>
                        </Col>
                      )
                    )}
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export { ViewAll };
