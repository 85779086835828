import React, { FC, Fragment, ReactNode, useRef } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { clsx } from 'clsx';
import { Button, Nav } from 'react-bootstrap';
import { array } from 'yup';
import { HomelyIcon } from '../../helpers/Utils';
import { usePageContext } from '../context/PageContext';

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  action?: any;
  access?: boolean | true | false;
  childrenId?: string;
  iconType?: string;
  className?: string;
};

export type WithChildren = {
  children?: ReactNode;
};

export function getCurrentUrl(pathname: string): { url: string } {
  const baseUrl = pathname.split(/[?#]/)[0];
  return { url: baseUrl };
}

export function checkIsActive(
  pathname: string,
  url: string,
  statusQuery: string
): boolean {
  const { url: current } = getCurrentUrl(pathname);
  const searchParams = new URLSearchParams(window.location.search);
  const status = searchParams.get('status');

  // Check if only one of the conditions is true
  const isUrlMatch = current === url;
  const isStatusMatch = status === statusQuery;

  return isUrlMatch && !(isUrlMatch && isStatusMatch);
}

const PageTabItem: FC<{
  className?: string;
  children?: ReactNode;
  to: string;
  title: string;
  icon?: ReactNode;
}> = ({ className, children, to, title, icon }) => {
  const { pathname } = useLocation();

  const [searchParams] = useSearchParams();
  const status = searchParams.get('status') || '';
  // Determine if the tab is active
  const isActive = checkIsActive(pathname, to, status);

  console.log(status, isActive);
  return (
    <Fragment>
      <Nav.Item className="mb-0" key={status}>
        <Nav.Link
          className={clsx(className, 'nav-link border-0 ms-1 p-2', {
            'hm-link-active': isActive,
          })}
          aria-current={isActive ? 'page' : undefined}
          to={to}
          as={Link}
          eventKey={title.toLowerCase()}
        >
          {icon} {title}
        </Nav.Link>
      </Nav.Item>
    </Fragment>
  );
};

const PageNav: FC<{
  items: Array<[string, string]>;
  action: boolean;
  actionProps: {
    type: string | 'button';
    buttonText?: string;
    buttonIcon?: string;
    buttonAction?: () => any;
    children?: ReactNode;
  };
}> = ({
  items,
  action,
  actionProps: { type, buttonText, buttonIcon, buttonAction, children },
}) => {
  const location = useLocation(); // Get current location

  return (
    <Fragment>
      <div className="d-flex justify-content-between   align-items-baseline flex-row flex-wrap p-0 gap-4 mb-3">
        {/* Navigation Pills */}
        <Nav
          fill
          variant="pills"
          className="d-flex justify-content-start hm-nav-tab p-1 bg-white align-content-start align-content-center-md flex-row shadow-sm text-start"
          defaultActiveKey="/listing/all"
        >
          {items.length > 0 &&
            items.map(([pageTitle, pageUrl], index) => {
              // Determine if the link is active
              const isActive =
                location.search.replace('?', '') ===
                pageUrl.split('?').reverse()[0];

              return (
                <PageTabItem
                  title={pageTitle}
                  to={pageUrl}
                  key={index}
                  className={isActive ? 'hm-link-active' : ''}
                />
              );
            })}
        </Nav>

        {/* Action Button */}
        {action && type === 'button' ? (
          <Button
            className="p-2 mx-2 rounded-sm hm-green-btn d-flex gap-2"
            size="sm"
            style={{ height: 'auto', flexShrink: 0 }}
            onClick={buttonAction}
          >
            {buttonIcon && (
              <HomelyIcon className="text-white" size={24} src={buttonIcon} />
            )}
            {buttonText}
          </Button>
        ) : (
          children
        )}
      </div>
    </Fragment>
  );
};
export { PageNav, PageTabItem };
