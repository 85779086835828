import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { axiosGet } from "../../../modules/helpers/RequestHelper";
import { DateTime } from "luxon"; // Import DateTime from luxon

import {
  Accordion,
  Button,
  Card,
  Col,
  Dropdown,
  Modal,
  Row,
} from "react-bootstrap";

import {
  CurrencyHelper,
  DateFormatter,
  NumberOfNightsHelper,
  PaymentStatusFormatter,
  ReservationStatusFormatter,
  SectionLoader,
} from "../../../modules/helpers/Utils";

import { Divider } from "antd";
import {
  ReservationIntials,
  ReservationModel,
  ReservationStatus,
} from "../../../modules/models/reservation.interface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { ResponseModel } from "../../../modules/models/general.interface";
import { usePageContext } from "../../../modules/layout/context/PageContext";
import { ReservationStatusModal } from "../../../modules/widgets/table/core/ColumnAction";

const ReservationView = () => {
  const { setLayoutType, setTitle } = usePageContext();
  const navigate = useNavigate();
  const { id } = useParams();
  const [partial, setPartial] = useState({
    openStatusModal: false,
  });
  const [data, setData] = useState<ReservationModel | any>(ReservationIntials);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    (async () => {
      try {
        const req = await axiosGet(`/reservation/list/${id}`);

        if (req?.success) {
          // Ensure you're setting the entire response object, not just the data field
          setData(req.data);
        }
      } catch (err: any) {
        setError(true);
        setErrorMessage(
          err.message || "An error occurred while fetching data.",
        );
      } finally {
        setLoading(false);
      }
    })();

    setLayoutType("full");
    setTitle(`View Reservation - ${id}`);
  }, [id, partial.openStatusModal]);

  console.log("data", data);

  const checkinDate = DateTime.fromISO(data.log?.checkin);
  const checkoutDate = DateTime.fromISO(data.log?.checkout);

  const handleEdit = (section: string) => {
    navigate(`/reservation/edit/${id}#${section}`);
  };
  const handleExport = () => {};
  return (
    <Fragment>
      <ReservationStatusModal
        show={partial.openStatusModal}
        onClose={() =>
          setPartial((prev) => ({
            ...prev,
            openStatusModal: !partial.openStatusModal,
          }))
        }
        status={data.status}
        id={data.id}
      />
      <Card>
        {!loading && !error && (
          <Card.Header className="d-flex align-items-baseline justify-content-between position-sticky top-0 z-5">
            <div className="d-flex justify-content-start gap-3 align-items-baseline">
              <p>
                <div className="text-muted">Reservation Date:</div>{" "}
                <DateFormatter date={data.created} />{" "}
              </p>
              <p className="">
                <div className=" text-muted">Status:</div>{" "}
                <ReservationStatusFormatter data={data.status} type="text" />
              </p>
              <div className="d-flex flex-column">
                <p className="">
                  <div className=" text-muted">Check-in Link:</div>{" "}
                  <Link
                    className="text-underline text-dark"
                    to={`https://bookhomely.com/reservation/guest/kyc/edit/${data.code}`}
                    target="_blank"
                  >
                    https://bookhomely.com/reservation/guest/kyc/edit/
                    {data.code}
                  </Link>
                </p>
                {data?.status === ReservationStatus.COMPLETED && (
                  <p className="">
                    <div className=" text-muted">Checkout Link :</div>{" "}
                    <Link
                      className="text-underline text-dark"
                      to={`https://bookhomely.com/reservation/guest/feedback/${data.code}`}
                      target="_blank"
                    >
                      https://bookhomely.com/reservation/guest/feedback
                      {data.code}
                    </Link>
                  </p>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end gap-3 align-items-baseline">
              <Dropdown style={{ fontSize: "12px" }}>
                <Dropdown.Toggle className="bg-transparent border-0 text-black">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ fontSize: "12px" }}>
                  <Dropdown.Item>Email to Guest</Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      setPartial((prev) => ({ ...prev, openStatusModal: true }))
                    }
                  >
                    Change Status
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to={`/reservation/edit/${id}`}
                    disabled={data?.status === ReservationStatus.COMPLETED}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleExport}>Export</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Card.Header>
        )}
        <Card.Body>
          {loading && <SectionLoader success={false} error={false} />}

          {error && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}

          {!loading && !error && (
            <Accordion
              defaultActiveKey="0"
              flush
              alwaysOpen
              className="fade-in"
            >
              <Accordion.Item
                eventKey="0"
                className="bg-white border-0"
                id="general"
              >
                <Accordion.Header className="border-0">
                  <h4 className="fw-bold lead border-0 text-uppercase">
                    Reservation Information
                  </h4>
                </Accordion.Header>
                <Accordion.Body className="px-0">
                  {/*Guest Information*/}
                  <div>
                    <Row className="mb-2">
                      <Col md={6}>
                        <h5 className="fw-medium lead hm-green-text text-uppercase">
                          Guest
                        </h5>
                      </Col>
                      <Col md={6} className="mb-3 d-flex justify-content-end">
                        <Button
                          variant="link"
                          className="text-underline"
                          onClick={() => handleEdit("guest")}
                        >
                          Edit
                        </Button>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={4} className="mb-3">
                        <div className="text-muted text-capitalize">
                          First Name
                        </div>
                        <p className="text-dark text-capitalize">
                          {data.user?.firstname ?? "Not available"}
                        </p>
                      </Col>
                      <Col md={4} className="mb-3">
                        <div className="text-muted text-capitalize">
                          Last Name
                        </div>
                        <p className="text-dark text-capitalize">
                          {data.user?.lastname ?? "Not available"}
                        </p>
                      </Col>
                      <Col md={4} className="mb-3">
                        <div className="text-muted text-capitalize">
                          Middle Name
                        </div>
                        <p className="text-dark text-capitalize">
                          {data.user?.middlename ?? "Not available"}
                        </p>
                      </Col>
                      <Col md={4} className="mb-3">
                        <div className="text-muted text-capitalize ">Email</div>
                        <p className="text-dark text-capitalize ">
                          {data.user?.email ?? "Not available"}
                        </p>
                      </Col>
                      <Col md={4} className="mb-3">
                        <div className="text-muted text-capitalize">Phone</div>
                        <p className="text-dark text-capitalize">
                          {data.user?.phone !== ""
                            ? data.user?.phone
                            : "Not available"}
                        </p>
                      </Col>
                      <Col md={4} className="mb-3"></Col>
                    </Row>
                  </div>

                  <Divider
                    style={{
                      borderWidth: "2px",
                      borderColor: "var(--hm-primary-gray-light)",
                    }}
                  />
                  {/*Property Information*/}
                  <div>
                    <Row className="mb-2">
                      <Col md={6}>
                        <h5 className="fw-medium lead hm-green-text text-uppercase">
                          General
                        </h5>
                      </Col>
                      <Col md={6} className="mb-3 d-flex justify-content-end">
                        <Button
                          variant="link"
                          className="text-underline"
                          onClick={() => handleEdit("general")}
                        >
                          Edit
                        </Button>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={4} className="mb-3">
                        <div className="text-muted">Property</div>
                        <p className="text-dark">
                          {data.property.internal_name}
                        </p>
                      </Col>
                      <Col md={4} className="mb-3">
                        <div className="text-muted text-capitalize">
                          Booking Source
                        </div>
                        <p className="text-dark">{data.source?.name}</p>
                      </Col>
                      <Col md={4} className="mb-3">
                        <div className="text-muted text-capitalize">
                          Reservation Code
                        </div>
                        <p className="text-dark">{data.code}</p>
                      </Col>
                    </Row>
                  </div>
                  <Divider
                    style={{
                      borderWidth: "2px",
                      borderColor: "var(--hm-primary-gray-light)",
                    }}
                  />
                  {/*Payment*/}
                  <div>
                    <Row className="mb-2">
                      <Col md={6}>
                        <h5 className="fw-medium lead hm-green-text text-uppercase">
                          Payment
                        </h5>
                      </Col>
                      <Col md={6} className="mb-3 d-flex justify-content-end">
                        <Button
                          variant="link"
                          className="text-underline"
                          onClick={() => handleEdit("payment")}
                        >
                          Edit
                        </Button>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col className="mb-3" md={4}>
                        <div className="text-muted text-capitalize">
                          Currency
                        </div>
                        <p className="text-dark">
                          {data.transaction?.currency ?? "₦"}
                        </p>
                      </Col>
                      <Col className="mb-3" md={4}>
                        <div className="text-muted text-capitalize">
                          Nightly Rate
                        </div>
                        <p className="text-dark">
                          <CurrencyHelper
                            price={data.property?.fee?.price}
                            prefix={data?.transaction?.currency ?? "₦"}
                          />
                        </p>
                      </Col>
                      <Col className="mb-3" md={4}>
                        <div className="text-muted text-capitalize">
                          Total Amount Received
                        </div>
                        <p className="text-dark">
                          <CurrencyHelper
                            price={
                              data.property?.fee?.price +
                              data.property?.fee?.service_charge +
                              data.property?.fee?.caution_charge
                            }
                            prefix={data.transaction?.currency}
                          />
                        </p>
                      </Col>
                      <Col className="mb-3" md={4}>
                        <div className="text-muted text-capitalize">
                          Service Charge
                        </div>
                        <p className="text-dark">
                          <CurrencyHelper
                            price={data.property.fee?.service_charge ?? 0}
                            prefix={data.transaction?.currency ?? "₦"}
                          />
                        </p>
                      </Col>
                      <Col className="mb-3" md={4}>
                        <div className="text-muted text-capitalize">
                          Caution Fee
                        </div>
                        <p className="text-dark">
                          <CurrencyHelper
                            price={data.property.fee?.caution_charge}
                            prefix={data.transaction?.currency}
                          />
                        </p>
                      </Col>
                      <Col className="mb-3" md={4}>
                        <div className="text-muted text-capitalize">
                          Discount
                        </div>
                        <p className="text-dark">
                          {data.transaction?.discount ?? `${0}%`}
                        </p>
                      </Col>
                      <Col className="mb-3" md={4}>
                        <div className="text-muted text-capitalize">
                          Payment Method
                        </div>
                        <p className="text-dark">
                          {data.transaction?.payment_method ?? "Not available"}
                        </p>
                      </Col>
                      <Col className="mb-3" md={4}>
                        <div className="text-muted text-capitalize">
                          Payment Status
                        </div>
                        <p className="text-dark">
                          <PaymentStatusFormatter
                            data={
                              data.transaction.status !== ""
                                ? data.transaction.status
                                : "Not available"
                            }
                          />
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Divider
                style={{
                  borderWidth: "4px",
                  borderColor: "var(--hm-primary-gray-light)",
                }}
              />
              <Accordion.Item
                eventKey="1"
                className="bg-white border-0"
                id="guest"
              >
                <Accordion.Header className="border-0">
                  <h4 className="fw-bold lead border-0 text-uppercase">
                    Check-in Information
                  </h4>
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <Row className="mb-2">
                      <Col md={12} className="mb-3 d-flex justify-content-end">
                        <Button
                          variant="link"
                          className="text-underline"
                          onClick={() => handleEdit("guest")}
                        >
                          Edit
                        </Button>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={4}>
                        <div className="text-muted text-capitalize">
                          Check In Date
                        </div>
                        <p className="text-dark text-capitalize">
                          <DateFormatter date={checkinDate} />
                        </p>
                      </Col>
                      <Col md={4}>
                        <div className="text-muted text-capitalize">
                          Check In Time
                        </div>
                        <p className="text-dark">
                          <DateFormatter date={checkinDate} displayOnlyTime />
                        </p>
                      </Col>
                      <Col md={4}>
                        <div className="text-muted text-capitalize">
                          Number of Nights
                        </div>
                        <p className="text-dark">
                          <NumberOfNightsHelper
                            checkIn={data.log?.checkin}
                            checkOut={data.log?.checkout}
                          />{" "}
                        </p>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={4}>
                        <div className="text-muted text-capitalize">
                          Number Of Adults
                        </div>
                        <p className="text-dark">{data.occupancy?.adult}</p>
                      </Col>
                      <Col md={4}>
                        <div className="text-muted text-capitalize">
                          Number of Children
                        </div>
                        <p className="text-dark">{data.occupancy?.children}</p>
                      </Col>
                      <Col md={4}>
                        <div className="text-muted text-capitalize">
                          Is there any pet?
                        </div>
                        <p className="text-dark">{data?.occupancy?.pet}</p>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={4}>
                        <div className="text-muted text-capitalize">
                          House No & Address
                        </div>
                        <p className="text-dark">
                          {data.user?.kyc?.address ?? "Not available"}
                        </p>
                      </Col>
                      <Col md={4}>
                        <div className="text-muted text-capitalize">
                          City of Residence
                        </div>
                        <p className="text-dark">
                          {data.user?.kyc?.city ?? "Not available"}
                        </p>
                      </Col>
                      <Col md={4}>
                        <div className="text-muted text-capitalize">
                          Country of Residence
                        </div>
                        <p className="text-dark">
                          {data.user?.kyc?.country ?? "Not available"}
                        </p>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={4}>
                        <div className="text-muted text-capitalize">
                          How did you learn about us
                        </div>
                        <p className="text-dark">
                          {data.source?.name ?? "Not available"}
                        </p>
                      </Col>
                      <Col md={4}>
                        <div className="text-muted text-capitalize">
                          Which one describes you
                        </div>
                        <p className="text-dark">
                          {data?.checkin?.guest_description ?? "Not available"}
                        </p>
                      </Col>
                      <Col md={4}>
                        <div className="text-muted text-capitalize">
                          Purpose of stay
                        </div>
                        <p className="text-dark">
                          {data?.checkin?.purpose ?? "Not available"}
                        </p>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={4}>
                        <div className="text-muted text-capitalize">
                          Government ID
                        </div>
                        <p className="text-dark">
                          {data?.user?.kyc?.id_type ??
                            "ID card Type not provided"}{" "}
                          -{" "}
                          {data?.user?.kyc?.id_card?.token ??
                            "ID card not uploaded"}
                        </p>
                      </Col>
                      <Col md={4}>
                        <div className="text-muted text-capitalize">
                          Government ID Expiry
                        </div>
                        <p className="text-dark">
                          {data?.user?.kyc?.id_expiry ?? "Not available"}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Divider
                style={{
                  borderWidth: "4px",
                  borderColor: "var(--hm-primary-gray-light)",
                }}
              />

              <Accordion.Item
                eventKey="2"
                className="bg-white border-0"
                id="checkout"
              >
                <Accordion.Header className="border-0">
                  <h4 className="fw-bold lead border-0 text-uppercase">
                    Check-out Information
                    {data.status !== ReservationStatus.COMPLETED &&
                      "- Only Available when reservation is completed."}
                  </h4>
                </Accordion.Header>
                {data.status === ReservationStatus.COMPLETED && (
                  <Accordion.Body>
                    <div>
                      <Row className="mb-2">
                        <Col
                          md={12}
                          className="mb-3 d-flex justify-content-end"
                        >
                          <Button
                            variant="link"
                            className="text-underline"
                            onClick={() => handleEdit("guest")}
                          >
                            Edit
                          </Button>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={4}>
                          <div className="text-muted text-capitalize">
                            Check Out Date
                          </div>
                          <p className="text-dark text-capitalize">
                            <DateFormatter date={checkoutDate} />
                          </p>
                        </Col>
                        <Col md={4}>
                          <div className="text-muted text-capitalize">
                            Check Out Time
                          </div>
                          <p className="text-dark">
                            <DateFormatter
                              date={checkoutDate}
                              displayOnlyTime
                            />
                          </p>
                        </Col>
                        <Col md={4}>
                          <div className="text-muted text-capitalize">
                            Average Rating
                          </div>
                          <p className="text-dark">4.9</p>
                        </Col>
                      </Row>
                      <h6 className="fw-bold mb-3">
                        Homely Performance Ratings
                      </h6>
                      <div className="mb-3">
                        <div className="d-flex justify-content-between">
                          <div className="text-muted text-capitalize">
                            Rate your overall experience with homely
                          </div>
                          <p className="text-dark">
                            {data?.feedback?.overallExperience ??
                              "Not available"}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="text-muted text-capitalize">
                            How likely are you to refer Homely?
                          </div>
                          <p className="text-dark">
                            {data?.feedback?.referralLikelihood ??
                              "Not available"}
                          </p>
                        </div>{" "}
                        <div className="d-flex justify-content-between">
                          <div className="text-muted text-capitalize">
                            How did Homely perform at Accuracy
                          </div>
                          <p className="text-dark">
                            {data?.feedback?.accuracy ?? "Not available"}
                          </p>
                        </div>
                      </div>
                      <h6 className="fw-bold mb-3">
                        Bank Information For Caution Fee Refund
                      </h6>
                      <Row className="mb-3">
                        <Col md={4}>
                          <div className="text-muted text-capitalize">
                            Bank Name
                          </div>
                          <p className="text-dark text-capitalize">
                            {data?.user?.wallet?.bank_name ?? "Not available"}
                          </p>
                        </Col>
                        <Col md={4}>
                          <div className="text-muted text-capitalize">
                            Account Name
                          </div>
                          <p className="text-dark">
                            {data?.user?.wallet?.bank_account_name ??
                              "Not available"}
                          </p>
                        </Col>
                        <Col md={4}>
                          <div className="text-muted text-capitalize">
                            Account Number
                          </div>
                          <p className="text-dark">
                            {data?.user?.wallet?.bank_account_number ??
                              "Not available"}
                          </p>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={112}>
                          <div className="text-muted text-capitalize">Note</div>
                          <p className="text-dark text-capitalize">
                            {data?.feedback?.review ?? "Not available"}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Accordion.Body>
                )}
              </Accordion.Item>
            </Accordion>
          )}
        </Card.Body>
      </Card>
    </Fragment>
  );
};
export { ReservationView };
