import { ReservationIntials, ReservationModel } from './reservation.interface';
import { UserIntials, UserModel } from './user.interface';

export type TransactionModel = {
  id: string; // Primary key
  total_fee: number | null; // Total fee of the transaction
  type: string | null; // Transaction type
  reference: string | null; // Reference string
  status: number | null; // Status of the transaction
  created: string; // Timestamp when the transaction was created
  updated: string; // Timestamp when the transaction was last updated
  reservation: null; // Associated reservation object
  user: UserModel; // Associated user object (replaces userId)
  discount: number | null; // Discount applied
  currency: string | null; // Currency of the transaction
  payment_method: string | null; // Payment method used
};

export const TransactionInitials: TransactionModel = {
  id: '',
  total_fee: null,
  type: null,
  reference: null,
  status: null,
  created: '',
  updated: '',
  reservation: null, // Use ReservationInitials as default
  user: UserIntials, // Use UserInitials as default
  discount: null,
  currency: null,
  payment_method: null,
};
