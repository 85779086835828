import React, { FC, Fragment, useState } from "react";
import { Button, Dropdown, Modal, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { EditPropertyCategoryModal } from "../../modals/category/EditPropertyCategoryModal";
import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosPut,
} from "../../../helpers/RequestHelper";
import { EditAmenityModal } from "../../modals/amenity/EditAmenityModal";

import { ReservationSourceModal } from "../../modals/reservation/ReservationSourceModal";
import { LocationModal } from "../../modals/location/LocationModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HomelyIcon } from "../../../helpers/Utils";
import viewIcon from "../../../../assets/icons/hmicons/viewIcon.svg";
import editIcon from "../../../../assets/icons/hmicons/editIcon.svg";
import changeIcon from "../../../../assets/icons/hmicons/changeIcon.svg";
import { ReservationView } from "../../../../pages/reservation/components/ReservationView";
import { Field, Formik, Form } from "formik";
import { ReservationStatus } from "../../../models/reservation.interface";
import { FormButton } from "../../FormButton";
import axios from "axios";
import { Notify } from "../../../helpers/NotificationHelper";

export const ReservationStatusModal = ({ ...props }) => {
  const [label, setLabel] = useState("Current Status");
  const handleSubmit = async (values: any) => {
    try {
      const req = await axios.patch(`/reservation/list/${props.id}`, {
        status: values.status,
      });
      if (req?.data?.success) {
        Notify({
          type: "toastr",
          toastrProps: { type: "success", text: req?.data?.message },
        });
        setTimeout(() => {
          window.location.reload();
        }, 600);
      } else {
        Notify({
          type: "toastr",
          toastrProps: { type: "error", text: req?.data?.message },
        });
      }
    } catch (error: any) {
      Notify({
        type: "toastr",
        toastrProps: { type: "error", text: error?.message },
      });
    } finally {
    }
  };
  return (
    <Fragment>
      <Modal show={props.show} onHide={props.onClose} centered backdrop>
        <Modal.Header closeButton>
          <h4 className="text-center">Change Reservation Status</h4>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{ status: props.status }}
            onSubmit={handleSubmit}
          >
            {({
              isSubmitting,
              setSubmitting,
              values,
              setFieldValue,
              isValid,
              setFieldError,
              handleChange,
              handleBlur,
            }) => (
              <Form>
                <Row className="">
                  <Col className="mb-5" md={12}>
                    <label className=" mb-1">{label}</label>
                    <Field
                      as="select"
                      name="status"
                      className="form-control"
                      autoFocus={true}
                      value={values.status}
                      defaultValue={props.status}
                      onChange={(selectedOption: any) => {
                        console.log(selectedOption);
                        setFieldValue("status", selectedOption.target.value);
                        setLabel(
                          `New Status - ${selectedOption.target.options[selectedOption.target.value].innerText}`,
                        );
                      }}
                    >
                      {Object.entries(ReservationStatus)
                        .sort()
                        .map(
                          ([key, value]) =>
                            // Exclude numeric values for reversed mapping (enums are both numeric and string)
                            isNaN(Number(key)) && (
                              <option key={value} value={value}>
                                {key.charAt(0).toUpperCase() +
                                  key.slice(1).toLowerCase()}
                              </option>
                            ),
                        )}
                    </Field>
                  </Col>
                  <Col className="mb-3" md={12}>
                    <FormButton
                      type="submit"
                      text="Save Changes"
                      className="hm-green-btn "
                      isSubmitting={isSubmitting}
                      isValid={isValid}
                    />
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
const ReservationColumnAction: FC<any> = ({ slug, data }) => {
  const [view, setView] = useState<boolean>(false);
  return (
    <Fragment>
      <ReservationStatusModal
        show={view}
        onClose={() => setView(!view)}
        status={data.status}
        id={data.id}
      />
      <Dropdown style={{ fontSize: "12px" }}>
        <Dropdown.Toggle className="bg-transparent border-0 text-black">
          <FontAwesomeIcon icon={faEllipsisH} />
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ fontSize: "11px", fontWeight: 500 }}>
          <Dropdown.Item
            as={Link}
            to={`/reservation/view/${slug}`}
            className="d-flex align-items-baseline fw-bold text-dark  gap-1"
            style={{ fontSize: "14px" }}
          >
            <HomelyIcon src={viewIcon} size={17} /> <span>View</span>
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            to={`/reservation/edit/${slug}`}
            className="d-flex align-items-baseline fw-bold text-dark  gap-1"
            style={{ fontSize: "14px" }}
          >
            <HomelyIcon src={editIcon} size={17} /> Edit
          </Dropdown.Item>
          <Dropdown.Item
            as={Button}
            onClick={() => setView(true)}
            className="d-flex align-items-baseline fw-bold text-dark  gap-1"
            style={{ fontSize: "14px" }}
          >
            <HomelyIcon src={changeIcon} size={17} /> Change Status
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};

const PropertyCategoryColumnAction: FC<any> = ({ data }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const handleDelete = async () => {
    const request = await axiosDelete(`/property/category/list/${data?.id}`);
  };
  return (
    <Fragment>
      <EditPropertyCategoryModal
        show={openEditModal}
        onHide={() => setOpenEditModal(false)}
        data={data}
      />
      <Dropdown style={{ fontSize: "12px" }}>
        <Dropdown.Toggle className="bg-transparent border-0 text-black">
          <FontAwesomeIcon icon={faEllipsisH} />
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ fontSize: "12px" }}>
          <Dropdown.Item onClick={() => setOpenEditModal(true)}>
            Edit
          </Dropdown.Item>
          <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};
const AmenityColumnAction: FC<any> = ({ data }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const handleDelete = async () => {
    const request = await axiosDelete(`/property/amenity/list/${data?.id}`);
  };
  return (
    <Fragment>
      <EditAmenityModal
        show={openEditModal}
        onHide={() => setOpenEditModal(false)}
        data={data}
      />
      <Dropdown style={{ fontSize: "12px" }}>
        <Dropdown.Toggle className="bg-transparent border-0 text-black">
          <FontAwesomeIcon icon={faEllipsisH} />
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ fontSize: "12px" }}>
          <Dropdown.Item onClick={() => setOpenEditModal(true)}>
            Edit
          </Dropdown.Item>
          <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};
const PlacesColumnAction: FC<any> = ({ data }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const handleDelete = async () => {
    const request = await axiosDelete(`/property/places/list/${data?.id}`);
  };
  return (
    <Fragment>
      <LocationModal
        show={openEditModal}
        onHide={() => setOpenEditModal(false)}
        data={data}
      />
      <Dropdown style={{ fontSize: "12px" }}>
        <Dropdown.Toggle className="bg-transparent border-0 text-black">
          <FontAwesomeIcon icon={faEllipsisH} />
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ fontSize: "12px" }}>
          <Dropdown.Item onClick={() => setOpenEditModal(true)}>
            Edit
          </Dropdown.Item>
          <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};
const SourceColumnAction: FC<any> = ({ data }) => {
  const [openEditModal, setOpenEditModal] = useState<any>(false);
  const handleDelete = async () => {
    const request = await axiosDelete(`/reservation/source/list/${data?.id}`);
  };
  return (
    <Fragment>
      <ReservationSourceModal
        show={openEditModal}
        onHide={() => setOpenEditModal(false)}
        data={data}
      />
      <Dropdown
        drop="up"
        style={{
          fontSize: "12px",
          position: "absolute",
          inset: "auto auto 0px 0px",
          transform: "translate3d(-123px, 4px, 0px)",
        }}
      >
        <Dropdown.Toggle className="bg-transparent border-0 text-black">
          <FontAwesomeIcon icon={faEllipsisH} />
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ fontSize: "12px" }}>
          <Dropdown.Item onClick={() => setOpenEditModal(true)}>
            Edit
          </Dropdown.Item>
          <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};
export {
  ReservationColumnAction,
  PropertyCategoryColumnAction,
  AmenityColumnAction,
  PlacesColumnAction,
  SourceColumnAction,
};
