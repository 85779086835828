import { FC, Fragment, useState } from 'react';
import {
  CloseButton,
  Col,
  FormGroup,
  Modal,
  ModalProps,
  Row,
} from 'react-bootstrap';
import Form from 'react-bootstrap/esm/Form';
import { FormButton } from '../../FormButton';
import { useFormik } from 'formik';
import { axiosPut } from '../../../helpers/RequestHelper';

const EditPropertyCategoryModal: FC<ModalProps> = ({ show, onHide, data }) => {
  const [value, setValue] = useState(data);

  const onValueChange = (e: any) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const initialValues = {
    name: data?.name,
    status: data?.status,
    description: data?.description,
  };
  const { getFieldProps, isSubmitting, handleSubmit, isValid } = useFormik({
    initialValues,
    // isInitialValid: true,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const req = await axiosPut(`/property/category/list`, {
          id: value?.id,
          name: value.name,
          status: value.status,
          description: value.description,
        });
      } catch (e) {
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <Fragment>
      <Modal size="lg" centered show={show} onHide={onHide}>
        <Modal.Header className="d-flex align-baseline">
          <h4>Edit - {data?.name}</h4> <CloseButton onClick={onHide} />
        </Modal.Header>
        <Modal.Body className="p-3">
          <form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    {...getFieldProps('name')}
                    value={value?.name}
                    name="name"
                    onChange={onValueChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Select {...getFieldProps('status')} name="status">
                    <option>Active</option>
                    <option>Inactive</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={12}>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  {...getFieldProps('description')}
                  value={value?.description}
                  name="description"
                  onChange={onValueChange}
                  rows={6}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={4}>
                <FormButton
                  type="submit"
                  isSubmitting={isSubmitting}
                  isValid={isValid}
                  text="Save Changes"
                />
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
export { EditPropertyCategoryModal };
