import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { ReservationList } from '../../../reservation/components/ReservationList';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosPut,
  axiosSpecial,
} from '../../../../modules/helpers/RequestHelper';
import {
  DateFormatter,
  inputFormatDate,
  LoaderHandler,
  SectionLoader,
} from '../../../../modules/helpers/Utils';
import { FormButton } from '../../../../modules/widgets/FormButton';

const ViewManage = () => {
  const [data, setData] = useState({
    id: useParams().id || '',
    isAvailable: false,
    isListed: false,
    availableDates: {
      start: '',
      end: '',
    },
    blockDate: {
      start: '',
      end: '',
    },
    sourceList: [],
    sourceId: '',
    calendarUrl: '',
  });

  const [partial, setPartial] = useState({
    loading: true,
    error: false,
    errorMessage: '',
    reload: false,
    navigate: useNavigate(),
    isSubmitting: false,
    isValid: true,
  });

  const fetchPropertyDetails = async () => {
    try {
      const [property, source] = await Promise.all([
        axiosGet(`/property/list/${data.id}`),
        axiosGet('/reservation/source/list'),
      ]);

      if (property?.success) {
        const { property: props } = property;
        setData((prev: any) => ({
          ...prev,
          isAvailable: props.available === 1,
          isListed: props.status === 1,
          calendarUrl: props?.calendar?.url,
          sourceId: props?.calendar?.source.id,
          sourceList: source?.success ? source?.source : [],
          blockDate: {
            ...prev.blockDate,
            end: props.calendar?.end_block_date ?? new Date(),
            start: props.calendar?.start_block_date ?? new Date(),
          },
          availableDates: {
            ...prev.availableDates,
            end: props.calendar?.end_available_date ?? new Date(),
            start: props.calendar?.start_available_date ?? new Date(),
          },
        }));
      }

      if (!property?.success) {
        setPartial((prev) => ({
          ...prev,
          error: true,
          errorMessage: property?.message,
        }));
      }
    } catch (error) {
      setPartial((prev) => ({
        ...prev,
        error: true,
        errorMessage: `Error fetching property: ${error}`,
      }));
    } finally {
      setPartial((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const handleAvailabilityChange = async (e: any) => {
    const checked = e.target.checked;
    setPartial((prev) => ({
      ...prev,
      loading: true,
    }));
    try {
      const response = await axiosSpecial({
        path: `/property/update-availability/${data.id}`,
        data: { available: checked ? 1 : 0 },
        title: `Do you want to make property ${data.isAvailable ? 'Unavailable' : 'Available'}?`,
        type: data.isAvailable ? 'Set Unavailable' : 'Set Available',
      });
      if (response.success) {
        setData((prev) => ({
          ...prev,
          isAvailable: checked,
        }));
        setPartial((prev) => ({
          ...prev,
          reload: true,
        }));
      }

      if (!response?.success) {
        setPartial((prev) => ({
          ...prev,
          error: true,
          errorMessage: response.message,
        }));
      }
    } catch (error) {
      setPartial((prev) => ({
        ...prev,
        error: true,
        errorMessage: `Error changing availability: ${error}`,
      }));
    } finally {
      setPartial((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const handleListingChange = async (e: any) => {
    const checked = e.target.checked;
    setPartial((prev) => ({
      ...prev,
      loading: true,
    }));
    try {
      const response = await axiosSpecial({
        path: `/property/update-status/${data.id}`,
        data: { status: checked ? 1 : 0 },
        title: `Do you want to take property ${data.isListed ? 'Offline' : 'Online'}`,
        type: data.isListed ? 'Take Offline' : 'Make Online',
      });
      if (response.success) {
        setData((prev) => ({
          ...prev,
          isListed: checked,
        }));
        setPartial((prev) => ({
          ...prev,
          reload: true,
        }));
      }
      if (!response?.success) {
        setPartial((prev) => ({
          ...prev,
          error: true,
          errorMessage: response.message,
        }));
      }
    } catch (error) {
      setPartial((prev) => ({
        ...prev,
        error: true,
        errorMessage: `Error changing listing status: ${error}`,
      }));
    } finally {
      setPartial((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const handleDelete = async () => {
    const req = await axiosDelete(`/property/list/${data.id}`);
    if (req?.success) {
      partial.navigate('/listing/all');
    }
  };

  const handleAvailableDates = async () => {
    const req = await axiosPut(`/property/calendar/dates/${data.id}`, {
      start_available_date: data?.availableDates.start,
      end_available_date: data?.availableDates.end,
      end_block_date: data?.blockDate.end,
      start_block_date: data?.blockDate.start,
    });
  };

  const handleCalendarUrlSave = async () => {
    setPartial((prev) => ({ ...prev, isSubmitting: true }));
    try {
      const req = await axiosPost(`/property/calendar/${data.id}`, {
        url: data.calendarUrl,
        source: data.sourceId,
      });
    } catch (err) {
    } finally {
      setPartial((prev) => ({ ...prev, isSubmitting: false }));
    }
  };
  useEffect(() => {
    // Function to fetch data
    const fetchData = () => {
      fetchPropertyDetails();
    };

    // Fetch data when component mounts or when reload is triggered
    if (partial.reload) {
      fetchData();
      setPartial((prev) => ({
        ...prev,
        reload: false,
      }));
    } else {
      fetchData();
    }
  }, [partial.reload]);

  return (
    <Fragment>
      <Row>
        <Col md={8}>
          <Card>
            <Card.Header>
              <h3>Manage</h3>
              <small className="text-muted">
                Manage property, edit, delete, block availability.
              </small>
            </Card.Header>
            <Card.Body>
              <LoaderHandler
                loading={partial.loading}
                error={partial.error}
                errorMessage={partial.errorMessage}
              >
                <Form className="mb-3">
                  <p>Set property external calendar</p>
                  <Row className="mb-2">
                    <Col md={6} className="mb-3">
                      <Form.Control
                        type="url"
                        placeholder="Enter Calendar url"
                        value={data.calendarUrl}
                        onChange={(e) =>
                          setData((prev: any) => ({
                            ...prev,
                            calendarUrl: e.target.value,
                          }))
                        }
                      />
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Select
                        onChange={(e) =>
                          setData((prev: any) => ({
                            ...prev,
                            sourceId: e.target.value,
                          }))
                        }
                      >
                        <option selected disabled>
                          Select Calendar Source
                        </option>
                        {data.sourceList?.length > 0 &&
                          data.sourceList.map(
                            ({ id, name }: { id: string; name: string }) => (
                              <option
                                value={id}
                                selected={id === data.sourceId}
                              >
                                {' '}
                                {name}
                              </option>
                            )
                          )}
                      </Form.Select>
                    </Col>
                  </Row>
                  <FormButton
                    type="button"
                    variant="primary"
                    isSubmitting={partial.isSubmitting}
                    isValid={partial.isValid}
                    text="Save"
                    onClick={handleCalendarUrlSave}
                  />
                </Form>
                <hr />
                <Row className="mb-4">
                  <Col md={6}>
                    <div className="mb-3">
                      <p>Block property, set property to unavailable</p>
                      <Form.Check
                        type="switch"
                        id="availability-switch"
                        label={
                          data.isAvailable ? 'Set Unavailable' : 'Set Available'
                        }
                        onChange={handleAvailabilityChange}
                        checked={data.isAvailable}
                      />
                    </div>
                    <hr />
                    <div className="">
                      <p>Block property dates.</p>
                      <Row>
                        <Col md={6}>
                          <p>Start Date.</p>
                          <Form.Control
                            value={inputFormatDate(data.blockDate.start)}
                            type="date"
                            id="block-date-start"
                            onChange={(e) =>
                              setData((prev: any) => ({
                                ...prev,
                                blockDate: {
                                  ...prev.blockDate,
                                  start: e.target.value,
                                },
                              }))
                            }
                          />
                        </Col>
                        <Col md={6}>
                          <p>End Date.</p>
                          <Form.Control
                            value={inputFormatDate(data.blockDate.end)}
                            type="date"
                            id="block-date-end"
                            onChange={(e) =>
                              setData((prev: any) => ({
                                ...prev,
                                blockDate: {
                                  ...prev.blockDate,
                                  end: e.target.value,
                                },
                              }))
                            }
                          />
                        </Col>
                        <Col md={12} className="mt-3">
                          <FormButton
                            type="button"
                            variant="primary"
                            isSubmitting={partial.isSubmitting}
                            isValid
                            text="Save"
                            onClick={handleAvailableDates}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <p>Take property offline, remove from listing</p>
                      <Form.Check
                        type="switch"
                        id="listing-switch"
                        label={data.isListed ? 'Take Offline' : 'Make Online'}
                        onChange={handleListingChange}
                        checked={data.isListed}
                      />
                    </div>
                    <hr />
                    <div className="mb-3">
                      <p>Set property available dates.</p>
                      <Row>
                        <Col md={6}>
                          <p>Start Date.</p>
                          <Form.Control
                            value={inputFormatDate(data.availableDates.start)}
                            onChange={(e) =>
                              setData((prev: any) => ({
                                ...prev,
                                availableDates: {
                                  ...prev.availableDates,
                                  start: e.target.value,
                                },
                              }))
                            }
                            type="date"
                            id="block-date-start"
                          />
                        </Col>
                        <Col md={6}>
                          <p>End Date.</p>
                          <Form.Control
                            value={inputFormatDate(data.availableDates.end)}
                            onChange={(e) =>
                              setData((prev: any) => ({
                                ...prev,
                                availableDates: {
                                  ...prev.availableDates,
                                  end: e.target.value,
                                },
                              }))
                            }
                            type="date"
                            id="block-date-end"
                          />
                        </Col>
                        <Col md={12} className="mt-3">
                          <FormButton
                            type="button"
                            variant="primary"
                            isSubmitting={partial.isSubmitting}
                            isValid
                            text="Save"
                            onClick={handleAvailableDates}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={6}>
                    <p>Change property name, price or anything you want.</p>
                    <Button
                      variant="outline-primary"
                      onClick={() =>
                        partial.navigate(`/listing/edit/${data.id}`)
                      }
                    >
                      Edit
                    </Button>
                  </Col>
                  <Col md={6}>
                    <p className="d-flex flex-column">
                      <span>Remove from database.</span>
                      <small className="text-warning">
                        <FontAwesomeIcon icon={faTriangleExclamation} /> Be sure
                        you know what you're doing.
                      </small>
                    </p>
                    <Button variant="outline-danger" onClick={handleDelete}>
                      Delete
                    </Button>
                  </Col>
                </Row>
              </LoaderHandler>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export { ViewManage };
