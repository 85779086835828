import { Fragment, useCallback, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';
import { axiosGet } from '../../modules/helpers/RequestHelper';
import { useNavigate } from 'react-router-dom';

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);
const now = new Date();

const CalendarPage = () => {
  const [events, setEvents] = useState<any[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      const req = await axiosGet('/reservation/list');

      if (req?.success) {
        const data = [];
        for (const value of req?.reservation) {
          const { log, property, id } = value;
          if (!log?.active) {
            data.push({
              ...value,
              id,
              title: `${property?.name} Reservation`,
              allDay: true,
              start: new Date(log?.checkin),
              end: new Date(log?.checkout),
            });
          }
        }
        setEvents(data);
      }
    })();
  }, []);

  const handleSelectEvent = useCallback((event: any) => {
    console.log(event);
    setTimeout(() => {
      navigate(`/reservation/${event?.id}`);
    }, 800);
  }, []);
  return (
    <Fragment>
      <Card>
        <Card.Body>
          <DnDCalendar
            localizer={localizer}
            events={events}
            draggableAccessor={(event) => false}
            style={{ height: 600 }}
            // showAllEvents={events}
            onSelectEvent={handleSelectEvent}
            // onSelectSlot={handleSelectSlot}
            selectable
          />
        </Card.Body>
      </Card>
    </Fragment>
  );
};
export { CalendarPage };
