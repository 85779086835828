import React, { FC, Fragment, useEffect, useState } from "react";
import { Table } from "../../../modules/widgets/table/Table";
import { ReservationColumn } from "../../../modules/widgets/table/core/Columns";
import { axiosGet, axiosPost } from "../../../modules/helpers/RequestHelper";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Modal,
  Row,
  Col,
  Button,
  Form as BootstrapForm,
  ButtonGroup,
  FormLabel,
  Nav,
} from "react-bootstrap";
import { usePageContext } from "../../../modules/layout/context/PageContext";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  FormikValues,
  FormikFormProps,
  FormikProps,
} from "formik";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { clsx } from "clsx";
import { LoaderHandler } from "../../../modules/helpers/Utils";
import { ReservationStatus } from "../../../modules/models/reservation.interface";
import { FormButton } from "../../../modules/widgets/FormButton";

const ReservationFilterModal = ({ ...props }) => {
  const [data, setData] = useState({
    property: [],
    source: [],
  });
  const initialValues = {
    bookingSource: "",
    checkIn: [null, null],
    checkOut: [null, null],
    reservationStatus: "",
    propertyName: { value: "", label: "" },
    guestName: "",
    bookingCode: "",
  };

  const [partial, setPartial] = useState({
    loading: true,
    error: false,
    errorMessage: "",
  });

  const fetchData = async () => {
    try {
      // Fetch property and sources concurrently
      const [propertyResponse, sourcesResponse] = await Promise.all([
        axiosGet("/property/list?limit"),
        axiosGet("/reservation/source/list"),
      ]);

      // Handle property response
      if (propertyResponse?.success) {
        const options = propertyResponse.properties.map((item: any) => ({
          value: item.id,
          label: item.internal_name,
        }));
        setData((prev) => ({ ...prev, property: options }));
      } else {
        setPartial((prev) => ({
          ...prev,
          error: true,
          errorMessage: "Error fetching property.",
        }));
      }

      // Handle sources response
      if (sourcesResponse?.success) {
        setData((prev) => ({ ...prev, source: sourcesResponse.source }));
      } else {
        setPartial((prev) => ({
          ...prev,
          error: true,
          errorMessage: "Error fetching source.",
        }));
      }
    } catch (error: any) {
      // Global error handling
      setPartial((prev) => ({
        ...prev,
        error: true,
        errorMessage: error.message || "Error fetching data.",
      }));
    } finally {
      // Remove loading state
      setPartial((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleSubmit = async (
    values: typeof initialValues,
    { setSubmitting, resetForm }: any,
  ) => {
    try {
      setSubmitting(true);
      console.log(values);
      const req = await axiosGet(
        `/reservation/admin/list/?property=${values.propertyName.value}&status=${values.reservationStatus}&source=${values.bookingSource}&checkIn=${values.checkIn}&checkOut=${values.checkOut}&guest=${values.guestName}&code=${values.bookingCode}`,
      );
      console.log(values, req);
      if (req?.success) {
        props.data(req.reservation);
        props.onClose(false);
        resetForm();
      }
    } catch (error: any) {
    } finally {
      setSubmitting(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  console.log(data);

  return (
    <Fragment>
      <Modal
        show={props.show}
        onBackdropClick={props.onClose}
        onHide={props.onClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter Reservation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3">
          <LoaderHandler
            loading={partial.loading}
            error={partial.error}
            errorMessage={partial.errorMessage}
          >
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {({
                errors,
                touched,
                values,
                setFieldValue,
                isSubmitting,
                isValid,
                resetForm,
              }) => (
                <Form className="d-flex  flex-column gap-4">
                  {/* Booking Source */}
                  <Row classsName="mb-5">
                    <Col md={12}>
                      {/* Guest Name */}
                      <BootstrapForm.Group>
                        <BootstrapForm.Label className="mb-2">
                          Booking Code
                        </BootstrapForm.Label>
                        <Field
                          type="text"
                          name="bookingCode"
                          className="form-control"
                          placeholder="Enter Booking Code"
                        />
                      </BootstrapForm.Group>
                      <ErrorMessage
                        name="bookingCode"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                  </Row>
                  <Row classsName="mb-5">
                    <Col md={6} classsName="mb-3">
                      <BootstrapForm.Group className="d-flex  flex-column gap-1">
                        <BootstrapForm.Label classsName="">
                          Booking Source
                        </BootstrapForm.Label>
                        <Nav
                          fill
                          variant="pills"
                          className="d-flex justify-content-between hm-border-gray py-2 px-1 rounded align-content-center flex-row text-start"
                        >
                          {data.source.length > 0 &&
                            data.source.map(({ id, name }) => (
                              <Button
                                key={id} // Place key here
                                type="button"
                                className={clsx(
                                  values.bookingSource === id
                                    ? "shadow rounded px-2"
                                    : "shadow-none px-0",
                                  "p-1 mx-2 rounded font-medium bg-white text-dark border-0 d-flex gap-2",
                                )}
                                size="sm"
                                style={{ height: "auto", flexShrink: 0 }}
                                data-id={id}
                                onClick={(event: any) => {
                                  const key = event.target.dataset.id;
                                  console.log(
                                    "Selected Booking Source ID:",
                                    key,
                                    id,
                                  );
                                  setFieldValue("bookingSource", key); // Update Formik value
                                }}
                              >
                                {name}
                              </Button>
                            ))}
                        </Nav>
                      </BootstrapForm.Group>

                      <ErrorMessage
                        name="bookingSource"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                  </Row>

                  {/* Date Range */}
                  <Row classsName="mb-5">
                    <Col md={6} classsName="mb-3">
                      <BootstrapForm.Group className="d-flex  flex-column gap-1">
                        <BootstrapForm.Label className="mb-2">
                          Check In
                        </BootstrapForm.Label>

                        <Field name="checkIn">
                          {({ field }: any) => (
                            <DatePicker
                              {...field}
                              selected={values.checkIn?.[0] || null} // Single date (for reference)
                              startDate={values.checkIn?.[0]} // Start date of the range
                              endDate={values.checkIn?.[1]} // End date of the range
                              onChange={(dates: [Date | null, Date | null]) => {
                                const [start, end] = dates; // Destructure range
                                console.log(dates, "selected dates");
                                setFieldValue("checkIn", [start, end]); // Update check-in
                              }}
                              selectsRange
                              inline
                              className="form-control w-100"
                            />
                          )}
                        </Field>
                      </BootstrapForm.Group>

                      <ErrorMessage
                        name="checkIn"
                        className="text-danger"
                        component="div"
                      />
                    </Col>
                    <Col md={6} classsName="mb-3">
                      <BootstrapForm.Group className="d-flex  flex-column gap-1">
                        <BootstrapForm.Label className="mb-2">
                          Check Out
                        </BootstrapForm.Label>

                        <Field name="checkOut">
                          {({ field }: any) => (
                            <DatePicker
                              {...field}
                              selected={values.checkOut?.[0] || null} // Single date (for reference)
                              startDate={values.checkOut?.[0]} // Start date of the range
                              endDate={values.checkOut?.[1]} // End date of the range
                              onChange={(dates: any) => {
                                const [start, end] = dates; // Destructure range
                                console.log(dates, "selected dates");
                                setFieldValue("checkOut", [start, end]); // Update check-out
                              }}
                              selectsRange
                              inline
                              className="form-control w-100"
                            />
                          )}
                        </Field>
                      </BootstrapForm.Group>

                      <ErrorMessage
                        name="checkOut"
                        className="text-danger"
                        component="div"
                      />
                    </Col>
                  </Row>

                  {/* Reservation Status */}
                  <Row className="mb-3">
                    <Col md={12}>
                      <BootstrapForm.Group className="d-flex  flex-column gap-1">
                        <BootstrapForm.Label classsName="">
                          Booking Source
                        </BootstrapForm.Label>
                        <Nav
                          fill
                          variant="pills"
                          className="d-flex justify-content-between hm-border-gray  rounded align-content-center flex-row text-start"
                        >
                          {Object.entries(ReservationStatus)
                            .filter(([key, value]) => !isNaN(Number(value))) // Filter out string keys
                            .sort() // Sort by numeric id
                            .map(([key, id]) => (
                              <Button
                                type="button"
                                key={Number(id)}
                                data-id={id}
                                onClick={(
                                  event: React.MouseEvent<HTMLButtonElement>,
                                ) => {
                                  const selectedId =
                                    event.currentTarget.dataset.id;
                                  console.log(
                                    "Selected ID:",
                                    selectedId,
                                    "Type:",
                                    typeof selectedId,
                                    "Parsed:",
                                    Number(selectedId),
                                  );
                                  setFieldValue(
                                    "reservationStatus",
                                    Number(selectedId),
                                  ); // Update Formik value
                                }}
                                className={clsx(
                                  Number(values.reservationStatus) ===
                                    Number(id) // Match selected value
                                    ? "shadow rounded px-2"
                                    : "shadow-none px-0",
                                  "p-1 mx-2 rounded text-capitalize font-medium bg-white text-dark border-0 d-flex gap-2",
                                )}
                                size="sm"
                                style={{ height: "auto", flexShrink: 0 }}
                              >
                                {key.toLowerCase()}{" "}
                                {/* Display the enum key in lowercase */}
                              </Button>
                            ))}
                        </Nav>
                      </BootstrapForm.Group>
                    </Col>
                  </Row>

                  {/* Property Name */}
                  <Row className="mb-3">
                    <Col md={6}>
                      <BootstrapForm.Group className="">
                        <BootstrapForm.Label className="mb-2">
                          Property Name
                        </BootstrapForm.Label>
                        <Field name="propertyName" className="form-select-lg ">
                          {({ field }: any) => (
                            <Select
                              {...field}
                              options={data.property}
                              value={values.propertyName}
                              onChange={(option: {
                                value: string;
                                label: string;
                              }) => setFieldValue("propertyName", option)}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                          )}
                        </Field>
                      </BootstrapForm.Group>
                    </Col>
                    <Col md={6}>
                      {/* Guest Name */}
                      <BootstrapForm.Group>
                        <BootstrapForm.Label className="mb-2">
                          Guest
                        </BootstrapForm.Label>
                        <Field
                          type="text"
                          name="guestName"
                          className="form-control"
                          placeholder="Enter guest email|phone|name"
                        />
                        {errors.guestName && touched.guestName && (
                          <div className="text-danger mt-2">
                            {errors.guestName}
                          </div>
                        )}
                      </BootstrapForm.Group>
                    </Col>
                  </Row>

                  {/* Submit Button */}
                  <Row className="mt-5 mb-3">
                    <Col md={6}>
                      <Button
                        type="reset"
                        className="w-100"
                        onClick={() => resetForm()}
                        variant="light"
                      >
                        Reset
                      </Button>
                    </Col>
                    <Col md={6}>
                      <FormButton
                        type="submit"
                        text="Apply"
                        isSubmitting={isSubmitting}
                        isValid={isValid}
                        className="hm-green-btn w-100"
                      />
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </LoaderHandler>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
const ReservationList: FC<{ props?: any }> = ({ props }) => {
  const { setLayoutType, setTitle } = usePageContext();
  const [data, setData] = useState<any[]>(props || []);
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [partial, setPartial] = useState({
    loading: false,
    error: false,
    errorMessage: "",
  });
  const [show, setShow] = useState(false);
  const status = searchParams.get("status");
  const [showFilter, setShowFilter] = useState(false);
  const fetchReservations = async () => {
    try {
      const getStatus = status !== null ? `?status=${status}` : "";
      const req = await axiosGet(
        `reservation/admin/list/${id ?? ""}${getStatus}`,
      );

      console.log("with Status", status);
      if (req?.success) {
        let { reservation } = req;

        console.log(reservation);
        // Normalize reservation to an array
        if (!Array.isArray(reservation)) {
          reservation = [reservation];
        }

        // const reserve_list = reservation.filter(
        //   (res: any) => res?.user !== null,
        // );
        setData(reservation);
      }
    } catch (error: any) {
      console.log(error);
      setPartial((prevState) => ({
        ...prevState,
        error: true,
        errorMessage: "Error fetching reservations",
      }));
    } finally {
      setPartial((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };
  useEffect(() => {
    fetchReservations();
    setLayoutType("regular");
    setTitle("Reservations");
  }, [id, status, setLayoutType, setTitle]);

  return (
    <Fragment>
      {/*Filter Props*/}

      <LoaderHandler
        loading={partial.loading}
        error={partial.error}
        errorMessage={partial.errorMessage}
      >
        <Table
          data={data}
          columns={ReservationColumn}
          headerTitle={` ${status || `All`} bookings (${data.length})`}
          filters={(open: boolean, setOpen: any) => (
            <ReservationFilterModal
              data={(value: any) => setData(value)}
              show={open}
              onClose={() => setOpen(false)} // Close modal when it's dismissed
            />
          )}
        />
      </LoaderHandler>
    </Fragment>
  );
};
export { ReservationList };
