export interface LoginInterface {
  email: string;
  password: string;
}

export interface UserModel {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  fax: string;
  middlename: string;
  password: string | null;
  google: boolean;
  verified: boolean;
  status: number;
  role: number;
  created: string;
  updated: string;
}

export const UserIntials: UserModel = {
  id: '',
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  fax: '',
  middlename: '',
  password: null, // Password can be null
  google: false, // Default to false
  verified: false, // Default to false
  status: 0,
  role: 0, // Default role
  created: '',
  updated: '',
};
