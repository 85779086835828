import { FC, Fragment, ReactNode, useEffect, useState } from "react";
//@ts-ignore
import { NumericFormat } from "react-number-format";
import {
  PaymentStatus,
  ReservationStatus,
} from "../models/reservation.interface";
import { Alert, Badge, Button } from "react-bootstrap";
import { AssetHelper } from "./AssetHelper";
import { Link } from "react-router-dom";
import { UploadFile, UploadProps } from "antd/lib/upload";
import Image from "react-bootstrap/Image";
import { Modal, Upload } from "antd";
import { RcFile } from "antd/es/upload";
import { Field, FieldProps } from "formik";
import { differenceInDays, startOfDay } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { BiLoader } from "@react-icons/all-files/bi/BiLoader";

const { Dragger } = Upload;

// Define the props type for the component

// A reusable date formatter component
const DateFormatter: FC<{
  date: string | Date; // Accepts both string and Date types
  locale?: string; // Optional prop to specify locale, defaulting to "en-US"
  options?: Intl.DateTimeFormatOptions; // Optional prop to override formatting options
  inputFormat?: boolean; // Optional prop to toggle input type formatting
  displayTime?: boolean; // Optional prop to control if time should be displayed with date
  displayOnlyTime?: boolean; // New prop to display only time
}> = ({
  date,
  locale = "en-US",
  options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  },
  inputFormat = false, // Default is false, making it suitable for display
  displayTime = false, // Display time along with date
  displayOnlyTime = false, // Display only time
}) => {
  const parsedDate = new Date(date);

  // Check if the date is valid before formatting
  if (isNaN(parsedDate.getTime())) {
    return <Fragment>Invalid Date</Fragment>;
  }

  // Format as "YYYY-MM-DD" for <input type="date">
  if (inputFormat) {
    const formattedInputDate = parsedDate.toISOString().split("T")[0];
    return <Fragment>{formattedInputDate}</Fragment>;
  }

  // Format for date only
  const formattedDate = parsedDate.toLocaleDateString(locale, options);

  // Format time if displayTime or displayOnlyTime is true
  const formattedTime = parsedDate.toLocaleTimeString(locale);

  // Show only time if displayOnlyTime is true
  if (displayOnlyTime) {
    return <Fragment>{formattedTime}</Fragment>;
  }

  // Show both date and time if displayTime is true
  return (
    <Fragment>
      {formattedDate}
      {displayTime && formattedTime && `, ${formattedTime}`}
    </Fragment>
  );
};

function inputFormatDate(date: any) {
  // Handle empty or undefined dates
  if (!date) {
    console.error("Date is null or undefined:", date);
    return "Invalid Date";
  }

  const parsedDate = new Date(date);

  // Check if the date is valid
  if (isNaN(parsedDate.getTime())) {
    console.error("Invalid date provided:", date);
    return "Invalid Date";
  }

  // Format as "YYYY-MM-DD"
  return parsedDate.toISOString().split("T")[0];
}

const CurrencyHelper: FC<any> = ({ price = 0, prefix = "₦", discount }) => {
  console.log(prefix);
  return (
    <Fragment>
      <NumericFormat
        prefix={prefix}
        value={price}
        allowLeadingZeros
        thousandSeparator=","
        displayType="text"
        decimalScale={3}
      />
    </Fragment>
  );
};

const PropertyFormatter: FC<any> = ({ data }) => {
  return (
    <Fragment>
      <Link
        to={`/listing/view/${data?.slug}`}
        className="d-flex align-items-center gap-2 my-2"
      >
        {/*{pictures.length > 0 &&*/}
        {/*  pictures.map(*/}
        {/*    (item: any) =>*/}
        {/*      item.front && (*/}
        {/*        <img*/}
        {/*          src={item.url}*/}
        {/*          alt={internal_name}*/}
        {/*          className=" rounded-circle"*/}
        {/*          height={60}*/}
        {/*          width={60}*/}
        {/*        />*/}
        {/*      ),*/}
        {/*  )}*/}
        <span>{data?.internal_name}</span>
      </Link>
    </Fragment>
  );
};
const UserFormatter: FC<any> = ({ data }) => {
  const { firstname, lastname, email, picture, id } = data;
  return (
    <Fragment>
      <Link
        to={`/users/${id}`}
        className="d-flex align-items-start flex-column gap-2 my-2"
      >
        {/*<img*/}
        {/*  src={picture?.url || AssetHelper("/assets/img/team/blank.webp")}*/}
        {/*  alt={email}*/}
        {/*  className=" rounded-circle"*/}
        {/*  height={60}*/}
        {/*  width={60}*/}
        {/*/>*/}

        <span>
          {firstname} {lastname}
        </span>
        <small>1 Guest</small>
      </Link>
    </Fragment>
  );
};
const ReservationStatusFormatter: FC<{
  data: any;
  type?: "text" | "styled";
}> = ({ data, type = "styled" }) => {
  const statusConfig: any = {
    [ReservationStatus.CONFIRMED]: { bg: "success", label: "Confirmed" },
    [ReservationStatus.COMPLETED]: { bg: "success", label: "Completed" },
    [ReservationStatus.FAILED]: { bg: "danger", label: "Failed" },
    [ReservationStatus.PENDING]: { bg: "warning", label: "Pending" },
    [ReservationStatus.ONGOING]: { bg: "secondary", label: "Ongoing" },
  };

  const { bg, label }: any = statusConfig[data] || {};

  return (
    <Fragment>
      {type === "styled" && label ? (
        <Alert key={bg} variant={bg} className="p-2 rounded-0">
          {label}
        </Alert>
      ) : null}

      {type === "text" && <div>{label}</div>}
    </Fragment>
  );
};
const PaymentStatusFormatter: FC<{ data: any; type?: string }> = ({
  data,
  type = "styled",
}) => {
  const statusConfig: any = {
    [PaymentStatus.PAID]: { bg: "success", label: "Paid" },
    [PaymentStatus.FAILED]: { bg: "danger", label: "Failed" },
    [PaymentStatus.PENDING]: { bg: "warning", label: "Pending" },
  };

  const { bg, label }: any = statusConfig[data] || {};

  return (
    <Fragment>
      {label && type !== "text" && (
        <Alert key={bg} variant={bg} className="p-2 rounded-0">
          {label}
        </Alert>
      )}
      {type === "text" && <div>{label}</div>}
    </Fragment>
  );
};
const useFileSelection = () => {
  const [selectedFiles, setSelectedFiles] = useState<any>([]);

  const addFile = (file: any) => {
    setSelectedFiles((currentSelection: any) => [...currentSelection, file]);
  };

  const removeFile = (file: any) => {
    setSelectedFiles((currentSelection: any) => {
      const newSelection = currentSelection.slice();
      const fileIndex = currentSelection.indexOf(file);
      newSelection.splice(fileIndex, 1);
      return newSelection;
    });
  };

  return [addFile, removeFile];
};
const useFilePreview = () => {
  const [previewVisibility, setPreviewVisibility] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<any>(null);
  const [previewTitle, setPreviewTitle] = useState<any>("");

  const getBase64Representation = (file: RcFile) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file: UploadFile) => {
    let { preview, url }: any = file;

    if (!url && !preview) {
      preview = await getBase64Representation(file.originFileObj as RcFile);
    }
    setPreviewImage(url || (preview as string));
    setPreviewVisibility(true);
    setPreviewTitle(file.name || url.substring(url.lastIndexOf("/") + 1));
  };

  const hidePreview = () => {
    setPreviewVisibility(false);
  };

  const previewContent: ReactNode = (
    <Modal
      open={previewVisibility}
      title={previewTitle}
      footer={null}
      onCancel={hidePreview}
    >
      <img alt={previewTitle} style={{ width: "100%" }} src={previewImage} />
    </Modal>
  );

  return { handlePreview, previewContent };
};

interface SortableUploadProps extends UploadProps {
  onSort: any;
  setFieldValue: any;
}

const DragAndDrop: FC<SortableUploadProps> = ({
  onSort,
  setFieldValue,
  fileList: propFileList = [],
  ...props
}) => {
  const { handlePreview, previewContent } = useFilePreview();

  const [fileList, setFileList] = useState<any[]>(propFileList); // Use propFileList as initial state
  const [loadDefaultFileList, setLoadDefaultFileList] = useState(true);
  const [addFile, removeFile] = useFileSelection();

  useEffect(() => {
    if (propFileList.length > 0 && loadDefaultFileList) {
      setFileList(propFileList);
    }
  }, [propFileList]);

  const handleChange = ({ fileList }: any) => {
    setLoadDefaultFileList(false);
    setFileList(fileList);
    setFieldValue("media", fileList);
  };

  const handleRemove = (file: any) => {
    const newFileList = fileList.filter((item: any) => item.uid !== file.uid);
    setFileList(newFileList);
    console.log(newFileList, "remmoved");
    setFieldValue("media", newFileList);
    setLoadDefaultFileList(false);
  };
  const handleSort = (dragIndex: number, hoverIndex: number) => {
    setLoadDefaultFileList(false);
    const dragFile = fileList[dragIndex];
    const newFileList = [...fileList];
    newFileList.splice(dragIndex, 1);
    newFileList.splice(hoverIndex, 0, dragFile);
    setFileList(newFileList);
    setFieldValue("media", newFileList);
  };
  const beforeUploadHandler = (file: UploadFile) => {
    addFile(file);
  };

  return (
    <Fragment>
      <Dragger
        multiple
        onRemove={handleRemove}
        showUploadList={true}
        listType="picture-card"
        beforeUpload={beforeUploadHandler}
        onPreview={handlePreview}
        accept="image/*"
        fileList={fileList}
        name={props.name}
        onChange={handleChange}
        itemRender={(originNode, file, currFileList) => (
          <SortableItem
            file={file}
            fileList={currFileList}
            handleSort={handleSort}
          >
            {originNode}
          </SortableItem>
        )}
      >
        <div className="d-flex flex-column align-items-center justify-content-center pb-6 pt-5">
          <svg
            className="mb-4 text-secondary"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 16"
            height={50}
            width={50}
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
            />
          </svg>
          <p className="mb-2 text-sm ">
            <span className="font-semibold">Click to upload</span> or drag and
            drop
          </p>
          <p className="text-xs">PNG, JPG or JPEG (MAX. 20MB)</p>
        </div>
      </Dragger>

      {previewContent}
    </Fragment>
  );
};

interface SortableItemProps {
  file: any;
  fileList: any[];
  children: any;
  handleSort: (dragIndex: number, hoverIndex: number) => void;
}

const SortableItem: React.FC<SortableItemProps> = ({
  file,
  fileList,
  handleSort,
  children,
}) => {
  const index = fileList.findIndex((f) => f.uid === file.uid);

  const dragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  // const dropHandler = (e: React.DragEvent<HTMLDivElement>) => {
  //   e.preventDefault();
  //   const dragIndex = fileList.findIndex((f) => f.uid === file.uid);
  //   const hoverIndex = fileList.findIndex(
  //     (f) => f.uid === e.dataTransfer.getData("uid"),
  //   );
  //   handleSort(dragIndex, hoverIndex);
  // };
  const dropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const dragUid = e.dataTransfer.getData("uid");
    const dragIndex = fileList.findIndex((f) => f.uid === dragUid);

    // Get the index where the item was dropped
    const hoverIndex = fileList.findIndex((f) => f.uid === file.uid);

    // Reorder the fileList array
    const updatedList = [...fileList];
    const [draggedItem] = updatedList.splice(dragIndex, 1);
    updatedList.splice(hoverIndex, 0, draggedItem);

    // Pass both dragIndex and hoverIndex to handleSort function
    handleSort(dragIndex, hoverIndex);
  };

  const dragStartHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.setData("uid", file.uid);
  };

  return (
    <div
      onDragOver={dragOverHandler}
      onDrop={dropHandler}
      draggable
      onDragStart={dragStartHandler}
      style={{ cursor: "move" }}
      title={file.name}
    >
      {children}
    </div>
  );
};

interface Image {
  id: number;
  src: string;
  caption: string;
}

const Gallery: React.FC = () => {
  const [images, setImages] = useState<Image[]>([]);
  const [newImageSrc, setNewImageSrc] = useState("");
  const [newImageCaption, setNewImageCaption] = useState("");

  const handleAddImage = () => {
    if (newImageSrc && newImageCaption) {
      const newImage: Image = {
        id: images.length + 1,
        src: newImageSrc,
        caption: newImageCaption,
      };
      setImages([...images, newImage]);
      setNewImageSrc("");
      setNewImageCaption("");
    }
  };

  const handleDeleteImage = (id: number) => {
    const updatedImages = images.filter((image) => image.id !== id);
    setImages(updatedImages);
  };

  const handleUpdateCaption = (id: number, newCaption: string) => {
    const updatedImages = images.map((image) =>
      image.id === id ? { ...image, caption: newCaption } : image,
    );
    setImages(updatedImages);
  };

  return (
    <div>
      <h2>Image Gallery</h2>
      <div>
        <input
          type="text"
          value={newImageSrc}
          onChange={(e) => setNewImageSrc(e.target.value)}
          placeholder="Image URL"
        />
        <input
          type="text"
          value={newImageCaption}
          onChange={(e) => setNewImageCaption(e.target.value)}
          placeholder="Image Caption"
        />
        <button onClick={handleAddImage}>Add Image</button>
      </div>
      <div>
        {images.map((image) => (
          <div key={image.id} style={{ marginBottom: "20px" }}>
            <img
              src={image.src}
              alt={image.caption}
              style={{ maxWidth: "200px" }}
            />
            <input
              type="text"
              value={image.caption}
              onChange={(e) => handleUpdateCaption(image.id, e.target.value)}
            />
            <button onClick={() => handleDeleteImage(image.id)}>Delete</button>
          </div>
        ))}
      </div>
    </div>
  );
};

interface SectionLoaderProps {
  success?: boolean;
  error?: boolean;
  errorMessage?: string;
}

const SectionLoader: FC<SectionLoaderProps> = ({
  success,
  error,
  errorMessage,
}) => {
  return (
    <Fragment>
      {!success && !error && (
        <div
          className="d-flex justify-content-center align-items-center flex-column"
          style={{
            height: "80vh",
          }}
        >
          <div className="loader"></div>
        </div>
      )}
      {error && (
        <div className="error-message">
          {errorMessage || "An error occurred."}
        </div>
      )}
    </Fragment>
  );
};

const LoaderHandler: FC<{
  loading?: boolean;
  error?: boolean;
  errorMessage?: string;
  children?: ReactNode;
  onRetryAction?: () => void;
}> = ({
  loading,
  error,
  errorMessage,
  children,
  onRetryAction = () => window.location.reload(),
}) => {
  const [showChildren, setShowChildren] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowChildren(true);
    }, 500);

    return () => clearTimeout(timer); // Clean up the timer
  }, []);
  return (
    <Fragment>
      {loading && (
        <div className="vh-100 d-flex flex-column align-items-center justify-content-center bg-white rounded-md">
          <div className=" loader" />
        </div>
      )}
      {error && !loading && (
        <div className="vh-100 d-flex flex-column align-items-center justify-content-center bg-white rounded-md">
          <p className="font-medium text-dark">Error occurred</p>
          {onRetryAction && (
            <Button
              color="danger"
              className="w-100"
              onClick={onRetryAction}
              aria-label="Retry"
            >
              Retry
            </Button>
          )}
        </div>
      )}
      {!loading && !error && showChildren && (
        <div className="fade-in">{children}</div>
      )}
    </Fragment>
  );
};

//ok
type HomelyIconProps = {
  name?: string; // Optional name prop for predefined icons
  src?: string;
  size?: number; // Optional size prop
  color?: string; // Optional color prop for SVG fill
  className?: string;
  WrapperClassName?: string;
};

const HomelyIcon: React.FC<HomelyIconProps> = ({
  name,
  src,
  size = 24,
  color,
  className,
  WrapperClassName,
}) => {
  // Render the component
  return (
    <div>
      {src && (
        <Image
          className={className}
          src={src}
          alt={name}
          width={size}
          height={size}
        />
      )}
    </div>
  );
};

const CurrencyField: FC<any> = ({ prefix, name, placeholder, ...props }) => {
  return (
    <Fragment>
      <div className="d-flex gap-2 align-items-center position-relative">
        <span className="position-absolute ml-1 right-1">{prefix}</span>{" "}
        {/* Currency symbol as a prefix */}
        <Field name={name}>
          {({ field }: FieldProps) => (
            <input
              {...field}
              id={name}
              type="text"
              className={props.class}
              pattern="[0-9]*" // Allows only numbers
              inputMode="numeric" // Shows numeric keypad on mobile
              placeholder={placeholder}
              onChange={props.onChange || field.onChange} // Use provided onChange or default Formik onChange
            />
          )}
        </Field>
      </div>
    </Fragment>
  );
};
const NumberOfNightsHelper: FC<any> = ({
  checkIn,
  checkOut,
  type = "component",
}) => {
  if (!checkIn || !checkOut) return null;

  const timeZone = "Africa/Lagos";
  const checkInInZone = startOfDay(toZonedTime(new Date(checkIn), timeZone));
  const checkOutInZone = startOfDay(toZonedTime(new Date(checkOut), timeZone));
  const nights = differenceInDays(checkOutInZone, checkInInZone);

  if (type !== "component") {
    return nights;
  }
  return (
    <p>
      {nights} {nights === 1 ? "Night" : "Nights"}
    </p>
  );
};
export {
  DateFormatter,
  CurrencyHelper,
  PropertyFormatter,
  UserFormatter,
  ReservationStatusFormatter,
  DragAndDrop,
  useFileSelection,
  Gallery,
  SectionLoader,
  LoaderHandler,
  inputFormatDate,
  HomelyIcon,
  CurrencyField,
  NumberOfNightsHelper,
  PaymentStatusFormatter,
};
