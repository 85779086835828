import React, { Fragment, useEffect, useState } from 'react';
import { ReservationList } from '../../../reservation/components/ReservationList';
import { Card, Col, Row } from 'react-bootstrap';
import { axiosGet } from '../../../../modules/helpers/RequestHelper';
import { useParams } from 'react-router-dom';

const ViewReservations = () => {
  const [data, setData] = useState({
    id: useParams().id,
    reservation: [],
  });

  const fetchData = async () => {
    const req = await axiosGet(`/property/list/${data.id}`);

    if (req.success) {
      const {
        property: { reservation },
      } = req;
      setData((prev) => ({ ...prev, reservation: reservation }));
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Fragment>
      <Row>
        <Col md={8}>
          <Card>
            <Card.Header>
              <h3>Reservations</h3>
              <small className="text-muted">View property reservations</small>
            </Card.Header>
            <Card.Body>
              <ReservationList props={data.reservation} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export { ViewReservations };
