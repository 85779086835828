import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { HomelyIcon } from '../helpers/Utils';
import backIcon from '../../assets/icons/hmicons/backIcon.svg';
import { usePageContext } from './context/PageContext';

const PageTitle = ({ ...props }) => {
  const { title, layoutType } = usePageContext();
  const navigate = useNavigate();
  useEffect(() => {
    // Update document title
    document.title = `${title} - Homely`;
    const handleBeforeUnload = (e: any) => {
      if (layoutType === 'full') {
        const confirmationMessage =
          'You have unsaved changes, are you sure you want to leave?';
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [title, layoutType]);

  const handleNavigation = () => {
    if (
      layoutType === 'full' &&
      !window.confirm('You have unsaved changes. Do you really want to leave?')
    ) {
      return;
    }
    navigate(-1);
  };

  return (
    <Fragment>
      <div className="d-flex align-items-center gap-2">
        {layoutType === 'full' && (
          <Button
            variant="light"
            className="p-0 m-0  bg-transparent"
            onClick={handleNavigation}
          >
            <HomelyIcon src={backIcon} />
          </Button>
        )}
        <h4 className="text-capitalize mb-0">{title}</h4>
      </div>
    </Fragment>
  );
};

export { PageTitle };
