import React, {
  FC,
  Fragment,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Sidebar } from './menu/Sidebar';
import Image from 'react-bootstrap/Image';
import { HomelyIcon } from '../helpers/Utils';
import menuIcon from '../../assets/icons/hmicons/menuIcon.svg';
import { AssetHelper } from '../helpers/AssetHelper';
import { Button } from 'react-bootstrap';
import Navbar from './menu/Navbar';
import NavbarComponent from './menu/Navbar';
import { MobileSidebar } from './menu/MobileSidebar';
import { PageProvider } from './context/PageContext';

const AppLayout: FC<any> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  // Function to close sidebar when clicked outside
  const handleClickOutside = (event: MouseEvent) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target as Node)
    ) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    // Attach the event listener to detect clicks outside
    if (isSidebarOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup the event listener on unmount or when sidebar is closed
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSidebarOpen]);
  return (
    <Fragment>
      <PageProvider>
        <div className="d-flex flex-column flex-md-row">
          {/* Sidebar: collapses to the top of the screen on small devices */}
          <div
            className="sidebar text-white p-3 vh-100 d-none d-md-block hm-nav-bg position-fixed top-0 h-100"
            style={{
              width: '250px',
              zIndex: 99999999,
            }}
          >
            <Sidebar />
          </div>

          <MobileSidebar toggle={toggleSidebar} />

          {/* Sidebar for mobile (collapsible) */}
          <div
            className={`sidebar hm-nav-bg p-3 vh-100 position-fixed d-md-none ${isSidebarOpen ? 'd-block' : 'd-none'}`}
            style={{ width: '250px', zIndex: 1050 }}
            ref={sidebarRef}
          >
            <Sidebar />
          </div>

          {/* Main content */}
          <div className="flex-grow-1 container-lg position-absolute-lg position-absolute-md position-absolute-xl  right-0 ">
            <div className="sticky-top d-none d-md-block container">
              <NavbarComponent className="w-100" />
            </div>

            <main className="container py-4 position-relative  w-100 fade-in">
              {children}
            </main>
          </div>
        </div>
      </PageProvider>
    </Fragment>
  );
};

export { AppLayout };
