import React, { Fragment, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Card, Col, Row, Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { axiosGet, axiosPost } from "../../../modules/helpers/RequestHelper"; // Import react-select
import CreatableSelect from "react-select/creatable";
import { Divider } from "antd";
import checkIcon from "../../../assets/icons/hmicons/checkIcon.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copyIcon from "../../../assets/icons/hmicons/copyIcon.svg";
import shareIcon from "../../../assets/icons/hmicons/shareIcon.svg";
import DatePicker from "react-datepicker";
import { WhatsappIcon } from "react-share";
import { WhatsappShareButton } from "react-share";
import {
  CurrencyField,
  CurrencyHelper,
  DateFormatter,
  HomelyIcon,
  LoaderHandler,
  NumberOfNightsHelper,
  PaymentStatusFormatter,
  ReservationStatusFormatter,
  SectionLoader,
} from "../../../modules/helpers/Utils";
import { FormButton } from "../../../modules/widgets/FormButton";
import { useNavigate, useParams } from "react-router-dom";
import { Simulate } from "react-dom/test-utils";
import copy = Simulate.copy;
import { Notify } from "../../../modules/helpers/NotificationHelper";
import {
  LogModel,
  ReservationIntials,
  ReservationModel,
  ReservationStatus,
  SourceModel,
} from "../../../modules/models/reservation.interface";
import { usePageContext } from "../../../modules/layout/context/PageContext";
import {
  PropertyIntials,
  PropertyModel,
} from "../../../modules/models/property.interface";
import { UserIntials, UserModel } from "../../../modules/models/user.interface";
import { ResponseModel } from "../../../modules/models/general.interface";
import { differenceInDays } from "date-fns";

const validationSchema = Yup.object().shape({
  property: Yup.object()
    .shape({
      value: Yup.string().required("Property selection is required"),
      label: Yup.string(), // or remove if not mandatory
    })
    .required("Property is required"),
  source: Yup.object()
    .shape({
      value: Yup.string().required("Source selection is required"),
      label: Yup.string(),
    })
    .required("Reservation source is required"),
  status: Yup.string().required("Status is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.object()
    .shape({
      value: Yup.string().required("Email is required"), // Ensure value is present
      label: Yup.string().email("Invalid email format"), // Validate email format
      // Optional, you can keep this if needed
    })
    .required("Email is required"), // Overall requirement for email field
  phone: Yup.object().shape({
    value: Yup.string().required("Phone is required"), // Ensure value is present
    label: Yup.number().required("Phone is required"), // Validate email format
    // Optional, you can keep this if needed
  }),
  adults: Yup.number().required("Number of adults is required"),
  children: Yup.number().required("Number of children is required"),
  pet: Yup.number().required("Pet is required"),
  checkIn: Yup.date().required("Check-in date is required"),
  nightsCount: Yup.number().optional(),
  checkOut: Yup.date().required("Check-out date is required"),
  currency: Yup.string().required("Currency is required"),
  price: Yup.number().required("Nightly Rate is required"),
  cautionFee: Yup.number().required("Caution Fee is required"),
  serviceCharge: Yup.number().required("Service Charge is required"),
  paymentStatus: Yup.string().required("Payment Status is required"),
  paymentMethod: Yup.string().required("Payment Method is required"),
});

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    minHeight: "inherit", // Adjust the height to match Bootstrap's form-control
    borderColor: "inherit", // Match Bootstrap's default input border color
    borderRadius: "inherit", // Bootstrap's border-radius for inputs
    boxShadow: "inherit", // Remove box shadow for focus consistency with Bootstrap
    "&:hover": {
      borderColor: "inherit", // Change border color on hover to match Bootstrap input hover
    },
  }),
};

const ReservationModifyNew = ({ ...props }) => {
  const { setLayoutType, setTitle } = usePageContext();
  const navigate = useNavigate();
  const [blockedDates, setBlockedDates] = useState<any>([]);
  const [data, setData] = useState<{
    property: PropertyModel[];
    log: LogModel[];
    guests: UserModel[];
    guestsEmail: { value: string; label: string }[];
    guestsPhone: { value: string; label: string }[];
    propertyOptions: { value: string; label: string }[];
    sourceOptions: { value: string; label: string }[];
    reservation: ReservationModel;
  }>({
    property: [PropertyIntials],
    log: [ReservationIntials.log],
    guests: [UserIntials],
    guestsEmail: [{ value: "", label: "" }],
    guestsPhone: [{ value: "", label: "" }],
    propertyOptions: [{ value: "", label: "" }],
    sourceOptions: [{ value: "", label: "" }],
    reservation: ReservationIntials,
  });
  const [initialValues, setInitialValues] = useState({
    property: {
      value: "",
      label: "",
    },
    source: { value: "", label: "" },
    status: "1",
    firstName: data.reservation.user.firstname,
    lastName: "",
    middleName: "",
    email: { value: "", label: "" },
    phone: { value: "", label: "" },
    nightsCount: 0,
    adults: "",
    children: "",
    pet: 0,
    checkIn: "",
    checkOut: "",
    currency: "",
    price: 0,
    discount: 0,
    cautionFee: 0,
    serviceCharge: 0,
    paymentStatus: "",
    paymentMethod: "",
  });

  const [partial, setPartial] = useState<any>({
    loading: true,
    error: false,
    errorMessage: "",
    reload: false,
    onRetry: () => {},
    isReview: false,
    isConfirmed: false,
    selectedCurrency: "₦",
  });

  const handleSubmit = async (
    values: any,
    { setSubmitting, resetForm }: any,
  ) => {
    try {
      if (!partial.isConfirmed && !props.id) {
        return false;
      }
      setSubmitting(true);
      console.log(values, "review", partial.isReview);

      const url = props?.id
        ? "/reservation/advanced/edit"
        : "/reservation/advanced/create";

      const req = await axiosPost(url, {
        reservationId: props.id && data?.reservation.id,
        reservationLogId: props.id && data?.reservation.log.id,
        user: {
          id: props.id && data.reservation.user.id,
          email: values.email.label,
          firstname: values.firstName,
          lastname: values.lastName,
          middlename: values.middleName,
          phone: values.phone.label,
          fax: values.fax,
        },
        property: {
          source: values.source.value,
          id: values.property.value,
          status: values.status,
        },
        guest: {
          adult: values.adults,
          children: values.children,
          pet: parseInt(values.pet),
          checkin: values.checkIn,
          checkout: values.checkOut,
          occupancyId: props.id && data.reservation.occupancy.id,
        },
        payment: {
          currency: values.currency,
          paymentMethod: values.paymentMethod,
          paymentStatus: values.paymentStatus,
          price: values.price,
          discount: values.discount,
          service_fee: values.serviceCharge,
          caution_fee: values.cautionFee,
          transactionId: props.id && data.reservation.transaction.id,
        },
      });
      console.log(req);
      if (req?.success) {
        if (!props.id) {
          resetForm();
          navigate(`/reservation/success/${req?.reservation?.code}`);
        } else {
          navigate(-1);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred during form submission. Please try again.");
    } finally {
      setSubmitting(false); // To reset the form submission state
    }
  };

  const fetchData = async () => {
    try {
      const [propertyRes, sourceRes, usersRes, reservationRes] =
        await Promise.all([
          axiosGet("/property/list?limit"),
          axiosGet("/reservation/source/list"),
          axiosGet("/users/guest/list"),
          props.id
            ? axiosGet(`/reservation/list/${props.id}`)
            : Promise.resolve(null),
        ]);

      if (propertyRes?.success) {
        const properties = propertyRes.properties;
        setData((prev: any) => ({
          ...prev,
          property: properties,
          propertyOptions: properties.map(({ internal_name, slug }: any) => ({
            value: slug,
            label: internal_name,
          })),
          log: properties.map((item: any) => ({
            reservation: item.reservation,
          })),
        }));
      }

      if (sourceRes?.success) {
        const sources = sourceRes.source;
        setData((prev: any) => ({
          ...prev,
          sourceOptions: sources.map(({ name, id }: any) => ({
            value: id,
            label: name,
          })),
        }));
      }

      if (usersRes?.success) {
        const guests = usersRes.guests;
        setData((prev: any) => ({
          ...prev,
          guests,
          guestsEmail: guests
            .map(({ email, id }: any) => ({ value: id, label: email }))
            .filter((guest: any) => guest.label !== null),
          guestsPhone: guests
            .map(({ phone, id }: any) => ({ value: id, label: phone }))
            .filter((guest: any) => guest.label !== null),
        }));
      }

      if (reservationRes && reservationRes?.success) {
        const { data: reservation } = reservationRes;
        setData((prev: any) => ({
          ...prev,
          reservation,
        }));
        setInitialValues({
          property: {
            value: reservation.property.slug,
            label: reservation.property.internal_name,
          },
          source: {
            value: reservation.source.id,
            label: reservation.source.name,
          },
          status: reservation.status,
          firstName: reservation.user.firstname,
          lastName: reservation.user.lastname || "",
          middleName: reservation.user.middlename || "",
          email: {
            value: reservation.user.id || "",
            label: reservation.user.email || "",
          },
          phone: {
            value: reservation.user.id || "",
            label: reservation.user.phone || "",
          },
          nightsCount: reservation.occupancy.nightsCount || 0,
          adults: reservation.occupancy.adult || "",
          children: reservation.occupancy.children || "",
          pet: reservation.occupancy.pet || 0,
          checkIn: reservation.log.checkin || "",
          checkOut: reservation.log.checkout || "",
          currency: reservation.transaction.currency || "",
          price: reservation.property.price || 0,
          discount: reservation.transaction.discount || 0,
          cautionFee: reservation.property.caution_charge || 0,
          serviceCharge: reservation.property.service_charge || 0,
          paymentStatus: reservation.transaction.status || "",
          paymentMethod: reservation.transaction.payment_msethod || "",
        });
      }
    } catch (err: any) {
      setPartial((prev: any) => ({
        ...prev,
        error: true,
        errorMessage: `${err.message}: An error occurred while fetching data.`,
      }));
    } finally {
      setPartial((prev: any) => ({ ...prev, loading: false }));
    }
  };
  const handleCreateUsers = (inputValue: any) => {
    console.log(inputValue);
    const newOption = {
      value: inputValue,
      label: inputValue,
    };
    setData((prevOptions: any) => ({ ...prevOptions, guestsEmail: newOption }));
  };

  const handleDiscount = (
    setFieldValue: any,
    discount: any,
    price: any,
    setFieldError: any,
  ) => {
    console.log(price, discount);
    if (!price) {
      setFieldError("price", "Nightly Rate is required");
      return false;
    }

    const discountFactor = 1 - discount / 100;
    const discountedPrice = price * discountFactor;
    console.log(discountedPrice);
    setFieldValue("price", discountedPrice);
  };

  const title = props.id
    ? `Edit Reservation ${props.id}`
    : "Create New Reservation";
  useEffect(() => {
    fetchData();
    setLayoutType("full");
    setTitle(title);
  }, [partial.isReview, props.id]);

  // const today = new Date().toISOString().slice(0, 16);

  const handleReview = () => {
    setPartial((prev: any) => ({ ...prev, isReview: true }));
    setPartial((prev: any) => ({ ...prev, isConfirmed: false }));
  };
  const handleConfirm = () => {
    setPartial((prev: any) => ({ ...prev, isConfirmed: true }));
  };
  console.log(data);
  return (
    <Fragment>
      <Card className="mb-4 border-0">
        <Card.Body>
          <LoaderHandler
            loading={partial.loading}
            error={partial.error}
            errorMessage={partial.errorMessage}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={!props?.id && validationSchema}
              onSubmit={handleSubmit}
              enableReinitializes
            >
              {({
                isSubmitting,
                setSubmitting,
                values,
                setFieldValue,
                isValid,
                setFieldError,
                handleChange,
                handleBlur,
                errors,
              }) => {
                {
                  console.log(errors);
                }

                return (
                  <Form autoFocus={true}>
                    {!partial.isReview && !partial.isConfirmed && (
                      <div className="fade-in">
                        <Row className="mb-4">
                          <Col md={6}>
                            <h5 className="fw-bold lead hm-green-text text-uppercase">
                              General
                            </h5>
                            <small className="text-light-emphasis">
                              Fill in general information about reservation.
                            </small>
                          </Col>
                          <Col md={6}>
                            <label className="text-muted mb-1">
                              Status <span className="text-danger">*</span>
                            </label>
                            <Field
                              as="select"
                              name="status"
                              className="form-control"
                              autoFocus={true}
                              defaultValue={data.reservation.status || ""}
                            >
                              <option value="" disabled selected>
                                Choose Status
                              </option>
                              {Object.entries(ReservationStatus).map(
                                ([key, value]) =>
                                  // Exclude numeric values for reversed mapping (enums are both numeric and string)
                                  isNaN(Number(key)) && (
                                    <option key={value} value={value}>
                                      {key.charAt(0).toUpperCase() +
                                        key.slice(1).toLowerCase()}
                                    </option>
                                  ),
                              )}
                            </Field>
                            <ErrorMessage
                              name="status"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                        </Row>

                        {/* Form fields go here, structured as described previously */}
                        <Row className="mb-3">
                          <Col md={6}>
                            <label className="text-muted mb-1">
                              Property <span className="text-danger">*</span>
                            </label>
                            <Select
                              name="property"
                              options={data.propertyOptions}
                              value={values.property} // Ensure this is set correctly
                              defaultValue={values.property}
                              onChange={(selectedOption: any) => {
                                console.log(selectedOption, "selected option");
                                setFieldValue("property", selectedOption);
                                const selectedProperty = data.property.find(
                                  (property: any) =>
                                    property.slug === selectedOption?.value,
                                );

                                if (selectedProperty) {
                                  setFieldValue(
                                    "price",
                                    selectedProperty.fee.price,
                                  );
                                  setFieldValue(
                                    "cautionFee",
                                    selectedProperty.fee.caution_charge,
                                  );
                                  setFieldValue(
                                    "serviceCharge",
                                    selectedProperty.fee.service_charge,
                                  );
                                  if (selectedProperty.reservation) {
                                    const dates =
                                      selectedProperty.reservation.flatMap(
                                        ({ log }: any) => {
                                          const start = new Date(log?.checkin);
                                          const end = new Date(log?.checkout);
                                          const range = [];
                                          while (start <= end) {
                                            range.push(new Date(start));
                                            start.setDate(start.getDate() + 1);
                                          }
                                          return range;
                                        },
                                      );
                                    setBlockedDates(dates);
                                  } else {
                                    setBlockedDates([]);
                                  }
                                } else {
                                  setFieldValue("price", "");
                                  setFieldValue("cautionFee", "");
                                  setFieldValue("serviceCharge", "");
                                  setBlockedDates([]);
                                }
                              }}
                              classNamePrefix="react-select"
                              className="form-control p-0" // p-0 to remove padding
                              styles={customStyles}
                              placeholder="Choose property"
                            />
                            <ErrorMessage
                              name="property.value"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md={6}>
                            <label className="text-muted mb-1">
                              Reservation Source{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              name="source"
                              options={data.sourceOptions}
                              value={values.source} // Ensure this is set correctly
                              onChange={(selectedOption: any) =>
                                setFieldValue("source", selectedOption)
                              }
                              classNamePrefix="react-select"
                              className="form-control p-0" // p-0 to remove padding
                              styles={customStyles}
                              placeholder="Choose reservation source"
                            />
                            <ErrorMessage
                              name="source.value"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                        </Row>

                        <Divider
                          style={{
                            borderWidth: "4px",
                            borderColor: "var(--hm-primary-gray-light)",
                          }}
                        />
                        <div className="mb-4">
                          <h5 className="fw-bold lead hm-green-text text-uppercase">
                            Guest
                          </h5>
                          <small className="text-light-emphasis">
                            Fill in information about guest.
                          </small>
                        </div>
                        <Row className="mb-3">
                          <Col className="mb-3" md={6}>
                            <label className="text-muted mb-1">
                              Email <span className="text-danger">*</span>
                            </label>
                            <CreatableSelect
                              id="email"
                              name="email"
                              options={data.guestsEmail}
                              value={values.email} // Ensure this is the correct structure
                              onChange={(selectedOption: any) => {
                                console.log(selectedOption);
                                setFieldValue("email", selectedOption);

                                const selectedUser = data.guests.find(
                                  (guest: any) =>
                                    guest.id === selectedOption?.value,
                                );

                                if (selectedUser) {
                                  // When email is selected, populate fields accordingly
                                  setFieldValue(
                                    "firstName",
                                    selectedUser.firstname,
                                  );
                                  setFieldValue(
                                    "lastName",
                                    selectedUser.lastname,
                                  );
                                  setFieldValue(
                                    "middleName",
                                    selectedUser.middlename,
                                  );
                                  setFieldValue("phone", {
                                    value: selectedUser.phone,
                                    label: selectedUser.phone,
                                  }); // Set phone when email is selected
                                } else {
                                  setFieldValue("firstName", "");
                                  setFieldValue("lastName", "");
                                  setFieldValue("middleName", "");
                                  setFieldValue("phone", ""); // Clear phone if no email is found
                                }
                              }}
                              onCreateOption={(inputValue) => {
                                handleCreateUsers(inputValue);
                                // Manually set the new input as the selected option
                                const newOption = {
                                  value: inputValue,
                                  label: inputValue,
                                };
                                setFieldValue("email", newOption);
                              }}
                              classNamePrefix="react-select"
                              placeholder="Search email or create guest"
                              isClearable
                              className="form-control p-0" // p-0 to remove padding
                              styles={customStyles}
                            />
                            <ErrorMessage
                              name="email.value" // Ensure this matches the structure in initialValues
                              component="div"
                              className="text-danger"
                            />
                          </Col>

                          <Col className="mb-3" md={6}>
                            <label className="text-muted mb-1">
                              Phone <span className="text-danger">*</span>
                            </label>
                            <CreatableSelect
                              id="phone"
                              name="phone"
                              options={data.guestsPhone}
                              value={values.phone} // Ensure this is the correct structure
                              onChange={(selectedOption: any) => {
                                console.log(selectedOption);
                                setFieldValue("phone", selectedOption);

                                const selectedUser = data.guests.find(
                                  (guest: any) =>
                                    guest.id === selectedOption?.value,
                                );
                                console.log(selectedUser);

                                if (selectedUser) {
                                  // When phone is selected, populate fields accordingly
                                  setFieldValue(
                                    "firstName",
                                    selectedUser.firstname,
                                  );
                                  setFieldValue(
                                    "lastName",
                                    selectedUser.lastname,
                                  );
                                  setFieldValue(
                                    "middleName",
                                    selectedUser.middlename,
                                  );
                                  setFieldValue("email", {
                                    value: selectedUser.email,
                                    label: selectedUser.email,
                                  }); // Set email when phone is selected
                                } else {
                                  setFieldValue("firstName", "");
                                  setFieldValue("lastName", "");
                                  setFieldValue("middleName", "");
                                  setFieldValue("email", ""); // Clear email if no phone is found
                                }
                              }}
                              onCreateOption={(inputValue) => {
                                handleCreateUsers(inputValue);
                                // Manually set the new input as the selected option
                                const newOption = {
                                  value: inputValue,
                                  label: inputValue,
                                };
                                setFieldValue("phone", newOption);
                              }}
                              classNamePrefix="react-select"
                              placeholder="Search phone or create with phone"
                              isClearable
                              className="form-control p-0" // p-0 to remove padding
                              styles={customStyles}
                            />
                            <ErrorMessage
                              name="phone.value"
                              component="div"
                              className="text-danger"
                            />
                          </Col>

                          <Col className="mb-3" md={4}>
                            <label className="text-muted mb-1">
                              First Name <span className="text-danger">*</span>
                            </label>
                            <Field
                              as="input"
                              name="firstName"
                              class="form-control"
                              placeholder="Enter First Name"
                              value={values.firstName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              name="firstName"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col className="mb-3" md={4}>
                            <label className="text-muted mb-1">
                              Last Name <span className="text-danger">*</span>
                            </label>
                            <Field
                              as="input"
                              name="lastName"
                              class="form-control"
                              placeholder="Enter Last Name"
                              value={values.lastName}
                            />
                            <ErrorMessage
                              name="lastName"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col className="mb-3" md={4}>
                            <label className="text-muted mb-1">
                              Middle Name
                            </label>
                            <Field
                              as="input"
                              name="middleName"
                              class="form-control"
                              placeholder="Enter Middle Name"
                            />
                            <ErrorMessage
                              name="middleName"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                        </Row>

                        <Divider
                          style={{
                            borderWidth: "4px",
                            borderColor: "var(--hm-primary-gray-light)",
                          }}
                        />
                        <div className="mb-4">
                          <h5 className="fw-bold lead hm-green-text text-uppercase">
                            Occupancy
                          </h5>
                          <small className="text-light-emphasis">
                            Fill in information about occupancy.
                          </small>
                        </div>
                        <Row className="mb-3">
                          <Col className="mb-3" md={4}>
                            <label className="text-muted mb-1">
                              Check In <span className="text-danger">*</span>
                            </label>
                            <Field name="checkIn">
                              {({ field, form }: any) => {
                                // Make sure values.checkIn is a valid Date object or null
                                const checkInDate = values.checkIn
                                  ? new Date(values.checkIn)
                                  : null;
                                return (
                                  <DatePicker
                                    {...field}
                                    selected={checkInDate} // Only wrap in new Date() if values.checkIn is a valid value
                                    onChange={(date) => {
                                      form.setFieldValue("checkIn", date);
                                      if (date && values.checkOut) {
                                        const difference = NumberOfNightsHelper(
                                          {
                                            checkIn: date,
                                            checkOut: values.checkOut,
                                            type: "function",
                                          },
                                        );
                                        form.setFieldValue(
                                          "nightsCount",
                                          difference,
                                        );
                                      }
                                    }} // Update Formik state
                                    minDate={new Date()} // Ensure dates in the past cannot be selected
                                    excludeDates={blockedDates} // Disable specific dates (ensure blockedDates is an array of Date objects)
                                    placeholderText="Enter Check-in"
                                    className="form-control w-100"
                                    wrapperClassName="form-control w-100"
                                  />
                                );
                              }}
                            </Field>
                            <ErrorMessage
                              name="checkIn"
                              component="div"
                              className="text-danger"
                            />
                          </Col>

                          <Col className="mb-3" md={4}>
                            <label className="text-muted mb-1">
                              Check Out <span className="text-danger">*</span>
                            </label>
                            <Field name="checkOut">
                              {({ field, form }: any) => {
                                // Make sure values.checkOut is a valid Date object or null
                                const checkOutDate = values.checkOut
                                  ? new Date(values.checkOut)
                                  : null;
                                return (
                                  <DatePicker
                                    {...field}
                                    selected={checkOutDate} // Only wrap in new Date() if values.checkOut is a valid value
                                    onChange={(date) => {
                                      form.setFieldValue("checkOut", date);
                                      if (values.checkIn && date) {
                                        const difference = NumberOfNightsHelper(
                                          {
                                            checkIn: values.checkIn,
                                            checkOut: date,
                                            type: "function",
                                          },
                                        );
                                        form.setFieldValue(
                                          "nightsCount",
                                          difference,
                                        );
                                      }
                                    }}
                                    // Update Formik state when the date changes
                                    minDate={values.checkIn || new Date()} // Ensure the check-out date is after the check-in date
                                    excludeDates={blockedDates} // Disable specific dates
                                    placeholderText="Enter Check-out"
                                    className="form-control w-100"
                                    wrapperClassName="form-control w-100"
                                  />
                                );
                              }}
                            </Field>
                            <ErrorMessage
                              name="checkOut"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col className="mb-3" md={4}>
                            <label className="text-muted mb-1">
                              Number of Nights{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Field
                              as="input"
                              name="nightsCount"
                              className="form-control"
                              value={values.nightsCount || ""}
                              placeholder="Number of Nights"
                              readOnly
                            />

                            <ErrorMessage
                              name="nightsCount"
                              component="div"
                              className="text-danger"
                            />
                          </Col>

                          <Col className="mb-3" md={4}>
                            <label className="text-muted mb-1">
                              Adults <span className="text-danger">*</span>
                            </label>
                            <Field
                              as="input"
                              name="adults"
                              class="form-control"
                              placeholder="Enter No. of Adults"
                            />
                            <ErrorMessage
                              name="adults"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col className="mb-3" md={4}>
                            <label className="text-muted mb-1">
                              Children <span className="text-danger">*</span>
                            </label>
                            <Field
                              as="input"
                              name="children"
                              class="form-control"
                              placeholder="Enter No. of Children"
                            />
                            <ErrorMessage
                              name="children"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col className="mb-3" md={4}>
                            <label className="text-muted mb-1">
                              Pets <span className="text-danger">*</span>
                            </label>
                            <Field as="select" name="pet" class="form-control">
                              <option value="" selected disabled>
                                Choose if any pets
                              </option>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </Field>
                            <ErrorMessage
                              name="pet"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                        </Row>

                        <Divider
                          style={{
                            borderWidth: "4px",
                            borderColor: "var(--hm-primary-gray-light)",
                          }}
                        />
                        <div className="mb-4">
                          <h5 className="fw-bold lead hm-green-text text-uppercase">
                            Payment
                          </h5>
                          <small className="text-light-emphasis">
                            Fill in information about payment.
                          </small>
                        </div>
                        <Row className="mb-3">
                          <Col className="mb-3" md={4}>
                            <label className="text-muted mb-1">
                              Currency <span className="text-danger">*</span>
                            </label>
                            <Field
                              as="select"
                              name="currency"
                              class="form-control"
                              onChange={(
                                event: React.ChangeEvent<HTMLSelectElement>,
                              ) => {
                                const selectedValue = event.target.value;

                                setFieldValue("currency", selectedValue); // Update Formik's field value

                                // Update custom state
                                setPartial((prev: any) => ({
                                  ...prev,
                                  selectedCurrency: selectedValue,
                                }));

                                console.log(
                                  "Selected currency:",
                                  selectedValue,
                                );
                              }}
                            >
                              <option value="" selected disabled>
                                Choose Currency
                              </option>
                              <option value="₦">NGN - ₦</option>
                              <option value="$">USD - $</option>
                              <option value="£">GBP - £</option>
                              <option value="€">EUR - €</option>
                            </Field>
                            <ErrorMessage
                              name="currency"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col className="mb-3" md={4}>
                            <label className="text-muted mb-1">
                              Payment Method{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Field
                              as="select"
                              name="paymentMethod"
                              class="form-control"
                            >
                              <option value="" selected disabled>
                                Choose Payment Method{" "}
                                <span className="text-danger">*</span>
                              </option>
                              <option value="card">Credit/Debit Card</option>
                              <option value="transfer">Bank Transfer</option>
                              <option value="cash">Cash</option>
                            </Field>
                            <ErrorMessage
                              name="paymentMethod"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col className="mb-3" md={4}>
                            <label className="text-muted mb-1">
                              Payment Status{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Field
                              as="select"
                              name="paymentStatus"
                              class="form-control"
                            >
                              <option value="" selected disabled>
                                Choose Payment Status
                              </option>
                              <option value="1">Paid</option>
                              <option value="2">Pending</option>
                              <option value="0">Failed</option>
                            </Field>
                            <ErrorMessage
                              name="paymentStatus"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="mb-3" md={3}>
                            <label className="text-muted mb-1">
                              Property Nightly Rate{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <CurrencyField
                              prefix={partial.selectedCurrency}
                              id="price"
                              as="input"
                              name="price"
                              class="form-control"
                              placeholder=""
                            />
                            <ErrorMessage
                              name="price"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col className="mb-3" md={3}>
                            <label className="text-muted mb-1">
                              Service Charge{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <CurrencyField
                              prefix={partial.selectedCurrency}
                              id="serviceCharge"
                              as="input"
                              name="serviceCharge"
                              class="form-control"
                              placeholder=""
                            />
                            <ErrorMessage
                              name="serviceCharge"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col className="mb-3" md={3}>
                            <label className="text-muted mb-1">
                              Caution Fee <span className="text-danger">*</span>
                            </label>
                            <CurrencyField
                              prefix={partial.selectedCurrency}
                              id="cautionFee"
                              as="input"
                              name="cautionFee"
                              class="form-control"
                              placeholder=""
                            />

                            <ErrorMessage
                              name="cautionFee"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col className="mb-3" md={3}>
                            <label className="text-muted mb-1">Discount</label>
                            <CurrencyField
                              prefix="%"
                              id="discount"
                              as="input"
                              name="discount"
                              class="form-control"
                              placeholder=""
                              value={values.discount}
                              onChange={(input: any) => {
                                setFieldValue("discount", input.target.value);
                                handleDiscount(
                                  setFieldValue,
                                  input.target.value,
                                  values.price,
                                  setFieldError,
                                );
                              }}
                            />

                            <ErrorMessage
                              name="discount"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                        </Row>
                      </div>
                    )}

                    {partial.isReview && (
                      <ReservationReview values={values} onShow={setPartial} />
                    )}

                    <Row className="mt-4 d-flex justify-content-start">
                      <Col md={4}>
                        <FormButton
                          type={
                            props.id
                              ? "submit"
                              : !partial.isConfirmed
                                ? "button"
                                : "submit"
                          }
                          className="w-100 hm-green-btn text-white"
                          isValid={isValid}
                          isSubmitting={
                            props.id
                              ? isSubmitting
                              : partial.isConfirmed
                                ? isSubmitting
                                : false
                          }
                          text={
                            props.id
                              ? "Save Changes"
                              : !partial.isReview
                                ? "Review"
                                : "Generate Link"
                          }
                          onClick={
                            props.id
                              ? null
                              : !partial.isReview
                                ? handleReview
                                : handleConfirm
                          }
                        />
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </LoaderHandler>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

const ReservationReview = ({ ...props }) => {
  document.title = "Review Reservation";
  const handleEdit = () => {
    props.onShow((prev: any) => ({
      ...prev,
      isReview: false,
      isConfirmed: false,
    }));
  };
  return (
    <Fragment>
      <Card>
        <Card.Body>
          <Row className="mb-3">
            <Col md={6} className="mb-3">
              <h5 className="fw-bold lead hm-green-text text-uppercase">
                General
              </h5>
            </Col>
            <Col md={6} className="mb-3 d-flex justify-content-end">
              <Button
                variant="link"
                className="text-underline"
                onClick={() =>
                  props.onShow((prev: any) => ({ ...prev, isReview: false }))
                }
              >
                Edit
              </Button>
            </Col>

            <Col md={4} className="mb-3">
              <div className="text-muted">Property</div>
              <p className="text-dark">{props.values.property.label}</p>
            </Col>
            <Col md={4} className="mb-3">
              <div className="text-muted text-capitalize">Booking Source</div>
              <p className="text-dark">{props.values.source.label}</p>
            </Col>
            <Col md={4} className="mb-3">
              <div className="text-muted text-capitalize">Status</div>
              <p className="text-dark">
                <ReservationStatusFormatter
                  type="text"
                  data={props.values.status}
                />
              </p>
            </Col>
          </Row>
          <Divider
            style={{
              borderWidth: "4px",
              borderColor: "var(--hm-primary-gray-light)",
            }}
          />
          <Row className="mb-3">
            <Col md={6}>
              <h5 className="fw-bold lead hm-green-text text-uppercase">
                Guest
              </h5>
            </Col>
            <Col md={6} className="mb-3 d-flex justify-content-end">
              <Button
                variant="link"
                className="text-underline"
                onClick={handleEdit}
              >
                Edit
              </Button>
            </Col>
          </Row>
          {/*Guest Information*/}
          <Row className="mb-3">
            <Col md={6} className="mb-3">
              <div className="text-muted text-capitalize ">Email</div>
              <p className="text-dark text-capitalize ">
                {props.values.email?.label ?? props.values.email}
              </p>
            </Col>
            <Col md={6} className="mb-3">
              <div className="text-muted text-capitalize">Phone</div>
              <p className="text-dark text-capitalize">
                {props.values.phone.label ?? props.values?.phone}
              </p>
            </Col>
          </Row>
          {/*Personal*/}
          <Row className="mb-3">
            <Col md={4} className="mb-3">
              <div className="text-muted text-capitalize">First Name</div>
              <p className="text-dark text-capitalize">
                {props.values.firstName}
              </p>
            </Col>
            <Col md={4} className="mb-3">
              <div className="text-muted text-capitalize">Last Name</div>
              <p className="text-dark text-capitalize">
                {props.values.lastName}
              </p>
            </Col>
            <Col md={4} className="mb-3">
              <div className="text-muted text-capitalize">Middle Name</div>
              <p className="text-dark text-capitalize">
                {props.values.middleName}
              </p>
            </Col>
          </Row>
          <Divider
            style={{
              borderWidth: "4px",
              borderColor: "var(--hm-primary-gray-light)",
            }}
          />
          <Row className="mb-3">
            <Col md={6}>
              <h5 className="fw-bold lead hm-green-text text-uppercase">
                Occupancy
              </h5>
            </Col>
            <Col md={6} className="mb-3 d-flex justify-content-end">
              <Button
                variant="link"
                className="text-underline"
                onClick={handleEdit}
              >
                Edit
              </Button>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4}>
              <div className="text-muted text-capitalize">Check In Date</div>
              <p className="text-dark text-capitalize">
                <DateFormatter date={props.values.checkIn} />
              </p>
            </Col>
            <Col md={4}>
              <div className="text-muted text-capitalize">Check Out Date</div>
              <p className="text-dark">
                <DateFormatter date={props.values.checkOut} />
              </p>
            </Col>
            <Col md={4}>
              <div className="text-muted text-capitalize">Number of Nights</div>
              <p className="text-dark">{props.values.nightsCount}</p>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4}>
              <div className="text-muted text-capitalize">Number Of Adults</div>
              <p className="text-dark">{props.values.adults}</p>
            </Col>
            <Col md={4}>
              <div className="text-muted text-capitalize">
                Number of Children
              </div>
              <p className="text-dark">{props.values.children}</p>
            </Col>
            <Col md={4}>
              <div className="text-muted text-capitalize">
                Is there any pet?
              </div>
              <p className="text-dark">{props.values.pet}</p>
            </Col>
          </Row>
          <Divider
            style={{
              borderWidth: "4px",
              borderColor: "var(--hm-primary-gray-light)",
            }}
          />
          <Row className="mb-3">
            <Col md={6}>
              <h5 className="fw-bold lead hm-green-text text-uppercase">
                Payment
              </h5>
            </Col>
            <Col md={6} className="mb-3 d-flex justify-content-end">
              <Button
                variant="link"
                className="text-underline"
                onClick={handleEdit}
              >
                Edit
              </Button>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4}>
              <div className="text-muted text-capitalize">Currency</div>
              <p className="text-dark">{props.values.currency}</p>
            </Col>
            <Col md={4}>
              <div className="text-muted text-capitalize">
                Property Rate Per Night
              </div>
              <p className="text-dark">
                <CurrencyHelper
                  price={props.values.price}
                  prefix={props.values.currency}
                />
              </p>
            </Col>
            <Col md={4}>
              <div className="text-muted text-capitalize">
                Total Amount Received
              </div>
              <p className="text-dark">
                <CurrencyHelper
                  price={
                    Number(props.values.price) +
                    Number(props.values.serviceCharge) +
                    Number(props.values.cautionFee)
                  }
                  prefix={props.values.currency}
                />
              </p>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4}>
              <div className="text-muted text-capitalize">Service Charge</div>
              <p className="text-dark">
                <CurrencyHelper
                  price={props.values.serviceCharge}
                  prefix={props.values.currency}
                />
              </p>
            </Col>
            <Col md={4}>
              <div className="text-muted text-capitalize">Caution Fee</div>
              <p className="text-dark">
                <CurrencyHelper
                  price={props.values.cautionFee}
                  prefix={props.values.currency}
                />
              </p>
            </Col>
            <Col md={4}>
              <div className="text-muted text-capitalize">Discount</div>
              <p className="text-dark">
                <CurrencyHelper
                  price={props.values.discount}
                  prefix={props.values.currency}
                />
              </p>
            </Col>
            <Col md={4}>
              <div className="text-muted text-capitalize">Payment Method</div>
              <p className="text-dark">{props.values.paymentMethod}</p>
            </Col>
            <Col md={4}>
              <div className="text-muted text-capitalize">Payment Status</div>
              <p className="text-dark">
                <PaymentStatusFormatter
                  type="text"
                  data={props.values.paymentStatus}
                />
              </p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Fragment>
  );
};
const ReservationSuccess = ({ ...props }) => {
  const { setLayoutType, setTitle } = usePageContext();
  const [data, setData] = useState<any>({
    reservation: [],
    property: [],
    user: [],
    userUrl: "",
  });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [partial, setPartial] = useState<any>({
    loading: true,
    error: false,
    errorMessage: "",
    reload: false,
  });
  const [copy, setCopy] = useState({
    value: "",
    copied: false,
  });
  const { id } = useParams();

  useEffect(() => {
    setTitle(`Reservation Success - ${id}`);
    setLayoutType("full");
  }, []);
  const fetchReservation = async () => {
    try {
      const req = await axiosGet(`/reservation/list/${id}`);
      console.log(req);
      if (req?.success) {
        setData({
          reservation: req?.data,
          property: req?.data?.property,
          user: req?.data?.user,
          userUrl: `${process.env.REACT_APP_CLIENT_URL}/reservation/guest/kyc/edit/${req?.data?.code}`,
        });

        setCopy({
          ...copy,
          value: `${process.env.REACT_APP_CLIENT_URL}/reservation/guest/kyc/edit/${req?.data?.code}`,
        });
      }
    } catch (err: any) {
      setPartial((prev: any) => ({
        ...prev,
        error: true,
        errorMessage: `${err.message}: An error occurred while fetching data.`,
      }));
    } finally {
      setPartial((prev: any) => ({ ...prev, loading: false }));
    }
  };
  useEffect(() => {
    fetchReservation();
  }, [id]);
  // console.log(data?.reservation.log);
  return (
    <Fragment>
      <Modal
        dismissible
        show={openModal}
        size={`sm`}
        onClose={() => setOpenModal(false)}
        centered
        onHide={() => setOpenModal(false)}
      >
        <Modal.Header closeButton>
          Share Reservation Code - {data?.code}
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center gap-3 align-items-center ">
            <WhatsappShareButton url={copy.value}>
              <WhatsappIcon size={50} round={true} />
            </WhatsappShareButton>
            <CopyToClipboard
              text={copy.value}
              onCopy={() => setCopy({ value: copy.value, copied: true })}
            >
              <Button
                className="bg-transparent "
                onClick={() =>
                  Notify({
                    type: "toastr",
                    toastrProps: { text: "Link copied", type: "success" },
                  })
                }
              >
                <HomelyIcon src={copyIcon} />
              </Button>
            </CopyToClipboard>
          </div>
        </Modal.Body>
      </Modal>
      <Card>
        <LoaderHandler
          loading={partial.loading}
          error={partial.error}
          errorMessage={partial.errorMessage}
        >
          <Card.Header className="text-center">
            <h3>Reservation Success</h3>
            <p className="text-muted">
              Your reservation has been created successfully and your link has
              been generated{" "}
            </p>
          </Card.Header>
          <Card.Body>
            <Row className="mb-3">
              <Col
                md={12}
                className="mb-3 d-flex justify-content-center align-items-center  flex-column"
              >
                <HomelyIcon src={checkIcon} size={80} />
                <h4>{data?.reservation?.code}</h4>
              </Col>
            </Row>
            <div className="hm-body-bg mb-5  p-3">
              <div className="mb-4">
                <h5 className="text-light-ellipsis">Reservation Details</h5>
              </div>

              <Row>
                <Col md={3}>
                  <div className="text-muted">Property</div>
                  <p className="text-dark text-captilize">
                    {data.property?.internal_name}
                  </p>
                </Col>
                <Col md={3}>
                  <div className="text-muted">Check-in</div>
                  <p className="text-dark text-captilize">
                    <DateFormatter date={data?.reservation?.log?.checkin} />
                  </p>
                </Col>
                <Col md={3}>
                  <div className="text-muted">Check-out</div>
                  <p className="text-dark text-captilize">
                    <DateFormatter date={data?.reservation?.log?.checkout} />
                  </p>
                </Col>
                <Col md={3}>
                  <div className="text-muted">Number of Nights</div>
                  <p className="text-dark text-captilize">
                    <NumberOfNightsHelper
                      checkIn={data?.reservation?.log?.checkin}
                      checkOut={data?.reservation?.log?.checkout}
                    />
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <div className="text-muted">Number of Guests</div>
                  <p className="text-dark text-captilize">
                    {data?.reservation?.occupancy?.adult +
                      data?.reservation?.occupancy?.children +
                      data?.reservation?.occupancy?.pet}
                  </p>
                </Col>
                <Col md={3}>
                  <div className="text-muted">Adults</div>
                  <p className="text-dark text-captilize">
                    {data?.reservation?.occupancy?.adult}{" "}
                  </p>
                </Col>
                <Col md={3}>
                  <div className="text-muted">Children</div>
                  <p className="text-dark text-captilize">
                    {data?.reservation?.occupancy?.children}
                  </p>
                </Col>
                <Col md={3}>
                  <div className="text-muted">Pet</div>
                  <p className="text-dark text-captilize">
                    {data?.reservation?.occupancy?.pet === 0 ? "No" : "Yes"}
                  </p>
                </Col>
              </Row>
            </div>

            <div className="d-flex justify-content-center align-items-center flex-column">
              <p className="text-muted">{data?.userUrl}</p>
              <div className="share-btn d-flex gap-3 mb-4 ">
                <CopyToClipboard
                  text={copy.value}
                  onCopy={() => setCopy({ value: copy.value, copied: true })}
                >
                  <Button
                    className="bg-transparent "
                    onClick={() =>
                      Notify({
                        type: "toastr",
                        toastrProps: { text: "Link copied", type: "success" },
                      })
                    }
                  >
                    <HomelyIcon src={copyIcon} />
                  </Button>
                </CopyToClipboard>
                <Button
                  className="bg-transparent "
                  onClick={() => setOpenModal(true)}
                >
                  <HomelyIcon src={shareIcon} />
                </Button>
              </div>
            </div>
          </Card.Body>
        </LoaderHandler>
      </Card>
    </Fragment>
  );
};

const ReservationModify = () => {
  const { id } = useParams();

  return (
    <Fragment>
      <ReservationModifyNew id={id} />
    </Fragment>
  );
};
export { ReservationModifyNew, ReservationSuccess, ReservationModify };
