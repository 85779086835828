import React, { Fragment, FC } from 'react';
import { Button } from 'react-bootstrap';
import { HomelyIcon } from '../../helpers/Utils';
import menuIcon from '../../../assets/icons/hmicons/menuIcon.svg';
import Image from 'react-bootstrap/Image';
import { AssetHelper } from '../../helpers/AssetHelper';
import Navbar from './Navbar';

export const MobileSidebar: FC<{ toggle: any }> = ({ ...props }) => {
  return (
    <Fragment>
      {/* Mobile Sidebar toggler */}
      <div className="d-md-none bg-white shadow-sm d-flex justify-content-between align-items-center p-2">
        <div className="d-flex align-items-center">
          <Button
            className="border-0 bg-transparent shadow-0"
            type="button"
            onClick={props.toggle}
            style={{
              border: 0,
              boxShadow: "none",
            }}
          >
            <HomelyIcon size={40} src={menuIcon} />
          </Button>
          <Image
            src={AssetHelper('/assets/img/brand/logo.svg')}
            alt="logo"
            style={{ width: '100px' }}
          />
        </div>
        <Navbar
          style={{
            paddingTop: 0,
          }}
        />
      </div>
    </Fragment>
  );
};
