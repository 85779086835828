import React, { FC, Fragment, useState } from 'react';
import {
  CloseButton,
  Col,
  FormGroup,
  Modal,
  ModalProps,
  Row,
} from 'react-bootstrap';
import Form from 'react-bootstrap/esm/Form';
import { FormButton } from '../../FormButton';
import { useFormik } from 'formik';
import { axiosPut } from '../../../helpers/RequestHelper';

const EditAmenityModal: FC<ModalProps> = ({ show, onHide, data }) => {
  const [value, setValue] = useState(data);

  const onValueChange = (e: any) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const initialValues = {
    name: data?.name,
    icon: data?.slug,
    description: data?.description,
  };
  const { getFieldProps, isSubmitting, handleSubmit, isValid } = useFormik({
    initialValues,
    // isInitialValid: true,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await axiosPut(`/property/amenity/list`, {
          id: value?.id,
          name: value?.name,
          slug: value?.icon,
          description: value?.description,
        });
      } catch (e) {
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <Fragment>
      <Modal size="lg" centered show={show} onHide={onHide}>
        <Modal.Header className="d-flex align-baseline">
          <h4>Edit - {value?.name}</h4> <CloseButton />
        </Modal.Header>
        <Modal.Body className="p-3">
          <form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    {...getFieldProps('name')}
                    value={value?.name}
                    name="name"
                    onChange={onValueChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    Icon{' '}
                    <small className="text-danger">
                      (User either remix icon or line awesome classname)
                    </small>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter icon classname. Ex: las la-car"
                    {...getFieldProps('icon')}
                    name="icon"
                    value={value?.slug}
                    onChange={onValueChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={12}>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  {...getFieldProps('description')}
                  value={value?.description}
                  name="description"
                  onChange={onValueChange}
                  rows={6}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={4}>
                <FormButton
                  type="submit"
                  isSubmitting={isSubmitting}
                  isValid={isValid}
                  text="Save Changes"
                />
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
export { EditAmenityModal };
