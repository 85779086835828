import React, { Fragment, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  Carousel,
  Button,
  Form,
  Table,
  Badge,
  ListGroup,
  Nav,
  Image,
} from 'react-bootstrap';
import { axiosGet } from '../../../../modules/helpers/RequestHelper';
import {
  CurrencyHelper,
  SectionLoader,
} from '../../../../modules/helpers/Utils';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowRight,
  faBed,
  faCircle,
  faExclamation,
  faMapMarkerAlt,
  faPaw,
  faPencilRuler,
  faShower,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { PageTabItem } from '../../../../modules/layout/menu/PageTabItem';
import { clsx } from 'clsx';
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { ViewNav } from './ViewNav';
import { ViewAll } from './ViewAll';

const ViewProperty = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true); // State for loading indicator
  const [error, setError] = useState(false); // State for error handling
  const [errorMessage, setErrorMessage] = useState(''); // Error message state
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const property = await axiosGet(`/property/list/${id}`);
        if (property?.success) {
          setData(property?.property);
        }
      } catch (err: any) {
        setError(true); // Set error state to true
        setErrorMessage(
          err.message || 'An error occurred while fetching data.'
        ); // Set error message
      } finally {
        setLoading(false); // Set loading state to false regardless of success or failure
      }
    })();
  }, []);

  console.log(data);
  return (
    <Fragment>
      {/* Display content if not loading and no error */}

      <Row className="clearfix">
        <Col md={12} lg={8}>
          <Card>
            <Card.Body>
              {/* Display loading spinner if loading */}
              {loading && <SectionLoader success={false} error={false} />}

              {/* Display error message if error */}
              {error && (
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              )}
              {!loading && !error && (
                <>
                  <Carousel
                    id="demo2"
                    nextIcon={<FontAwesomeIcon icon={faArrowRight} />}
                    prevIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                  >
                    {data?.pictures?.length > 0 &&
                      data.pictures.map(({ token, id, name }: any) => (
                        <Carousel.Item key={id}>
                          <Image
                            src={token}
                            className="d-block w-100 carousel-item img"
                            alt={name}
                          />
                          <Carousel.Caption className="text-white">
                            <h3>{data?.name}</h3>
                            <p>{data?.location?.address}</p>
                          </Carousel.Caption>
                        </Carousel.Item>
                      ))}
                  </Carousel>

                  <div className="d-flex justify-content-between align-items-baseline">
                    <h6 className="text-success mt-3">
                      {<CurrencyHelper price={data?.fee?.price} />}
                    </h6>
                    <div>
                      {data.available === 1 && data.status === 1 && (
                        <Fragment>
                          <FontAwesomeIcon
                            icon={faCircle}
                            style={{ color: '#32CD32' }}
                          />
                          <span className="text-muted ms-1">Online</span>
                        </Fragment>
                      )}
                      {data.available === 0 && (
                        <Fragment>
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="text-secondary"
                          />
                          <span className="text-muted ms-1">Unavailable</span>
                        </Fragment>
                      )}
                      {data.status === 0 && (
                        <Fragment>
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="text-danger"
                          />
                          <span className="text-muted ms-1">Offline</span>
                        </Fragment>
                      )}
                    </div>
                  </div>

                  <h5 className="mt-0">
                    <a href="#" className="col-blue-grey">
                      {data?.internal_name} | {data?.name}
                    </a>
                  </h5>
                  <p className="text-muted text-ellipsis">
                    {data?.description}
                  </p>
                  <small className="text-muted ">
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2" />
                    {data?.location?.address} {data?.location?.place?.city}{' '}
                    {data?.location?.zipcode}
                  </small>
                  <div className="d-flex flex-wrap gap-2 justify-content-start mt-3 p-3 bg-light">
                    <a href="#" className="w100" title="Square Feet">
                      <FontAwesomeIcon icon={faUser} className="me-2" />
                      <span>Guests - {data?.occupancy?.max_occupancy}</span>
                    </a>
                    <a href="#" className="w100" title="Bedroom">
                      <FontAwesomeIcon icon={faBed} className="me-2" />
                      <span>Bedroom - {data?.occupancy?.bedroom}</span>
                    </a>
                    <a href="#" className="w100" title="Bathroom ">
                      <FontAwesomeIcon icon={faShower} className="me-2" />
                      <span>Bathroom - {data?.occupancy?.bathroom}</span>
                    </a>
                    <a href="#" className="w100" title="Garages">
                      <FontAwesomeIcon icon={faPaw} className="me-2" />
                      <span>
                        Pets - {data?.occupancy?.pet === 1 ? 'Yes' : 'No'}
                      </span>
                    </a>
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
          <Col md={12} lg={12}>
            <ViewNav />
          </Col>
        </Col>
        <Col lg={4} md={12}>
          <Card>
            <Card.Body className="text-center">
              <a href="#">
                <Image
                  src={data?.user?.picture ?? '/assets/img/team/blank.webp'}
                  className="rounded-circle"
                  alt="profile-image"
                  width={90}
                />
              </a>
              <h4 className="mt-3">
                {data?.user?.firstname && data?.user?.lastname
                  ? `${data?.user.firstname} ${data?.user.lastname}`
                  : 'N/A'}
              </h4>
              <div className="col-12">
                <ul className="d-flex justify-content-center gap-3 list-unstyled">
                  <li className="w30">
                    <a title="facebook" href="#">
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                  </li>
                  <li className="w30">
                    <a title="twitter" href="#">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                  <li className="w30">
                    <a title="instagram" href="#">
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                </ul>
                <p className="text-muted">{data?.user?.phone ?? 'N/A'}</p>
              </div>
              <Row>
                <Col xs={4}>
                  <h5>18</h5>
                  <small>Properties</small>
                </Col>
                <Col xs={4}>
                  <h5>5</h5>
                  <small>Reservations</small>
                </Col>
                <Col xs={4}>
                  <h5>
                    <CurrencyHelper price="4000" />
                  </h5>
                  <small>Earned</small>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="d-none">
            <Card.Header>
              <h2>
                <strong>Overview</strong>
                <small>Description text here...</small>
              </h2>
            </Card.Header>
            <Card.Body>
              <Table responsive className="table-hover">
                <tbody>
                  <tr>
                    <td>
                      <i className="zmdi zmdi-view-dashboard mr-2"></i>
                      <span>Size</span>
                    </td>
                    <td className="text-right">
                      <strong>280 sqft</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <i className="zmdi zmdi-hotel mr-2"></i>
                      <span>Bed</span>
                    </td>
                    <td className="text-right">
                      <strong>4</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <i className="zmdi zmdi-car-taxi mr-2"></i>
                      <span>Bath</span>
                    </td>
                    <td className="text-right">
                      <strong>4</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <i className="zmdi zmdi-car-taxi mr-2"></i>
                      <span>Garage</span>
                    </td>
                    <td className="text-right">
                      <strong>2</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <i className="zmdi zmdi-seat mr-2"></i>
                      <span>Seats</span>
                    </td>
                    <td className="text-right">
                      <strong>5</strong>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>

          <Card className="mt-4">
            <Card.Header>Send Message</Card.Header>
            <Card.Body>
              <div className="form-group">
                <Form.Control
                  type="text"
                  placeholder="Subject"
                  className="mb-3"
                />
                <Form.Control
                  as="textarea"
                  placeholder="Write.."
                  className="mb-3"
                />
              </div>
              <Button variant="primary" className="btn-round">
                Submit
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export { ViewProperty };
