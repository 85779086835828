import React, { Fragment, useEffect, useState } from 'react';
import CalendarComponent from '../../../components/Calendar';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { axiosGet, axiosPost } from '../../../../modules/helpers/RequestHelper';
import { prop } from 'react-data-table-component/dist/DataTable/util';
import { FormButton } from '../../../../modules/widgets/FormButton';

const ViewCalendar = () => {
  const [data, setData] = useState({
    id: useParams().id,
    sourceId: '',
    calendarUrl: '',
    events: [],
  });
  const [partial, setPartial] = useState({
    isSubmitting: false,
    reload: false,
  });

  const fetchData = async () => {
    const req = await axiosGet(`/property/list/${data.id}`);

    if (req?.success) {
      const { property } = req;
      console.log(property);
      setData((prevState) => ({
        ...prevState,
        calendarUrl: property?.calendar?.url,
        sourceId: property?.calendar?.source.id,
      }));
    }
  };
  const handleCalendarImport = async () => {
    setPartial((prev) => ({ ...prev, isSubmitting: true }));

    try {
      const response = await axiosPost(`/property/calendar/import/${data.id}`, {
        url: data.calendarUrl,
        sourceId: data.sourceId,
      });
    } catch (err) {
    } finally {
      setPartial((prev) => ({ ...prev, isSubmitting: false, reload: true }));
    }
  };
  useEffect(() => {
    // Fetch initial data
    if (partial.reload) {
    }
    fetchData();
    //
    // // Set up interval to call handleCalendarImport every 5 minutes
    // const interval = setInterval(() => {
    //   handleCalendarImport();
    // }, 300000); // 300000 milliseconds = 5 minutes
    //
    // // Cleanup function to clear the interval
    // return () => clearInterval(interval);
  }, [data?.id]);
  return (
    <Fragment>
      <Row>
        <Col md={8}>
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-baseline">
              <div>
                <h3 className="leading">Calendar</h3>
                <small className="text-muted">
                  Manage property calendar, import external calendar, see
                  current and upcoming reservations.
                </small>
              </div>
              <div>
                <FormButton
                  isSubmitting={partial.isSubmitting}
                  text="Refresh"
                  variant="primary"
                  onClick={handleCalendarImport}
                  type="button"
                  isValid={true}
                />
              </div>
            </Card.Header>
            <Card.Body>
              <CalendarComponent reload={partial.reload} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
export { ViewCalendar };
