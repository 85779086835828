import React, { FC, Fragment } from 'react';
import { clsx } from 'clsx';
import { Button } from 'react-bootstrap';
import { ButtonVariant } from 'react-bootstrap/types';

type FormButtonProps = {
  type: 'button' | 'submit' | 'reset' | undefined;
  className?: string;
  text: string;
  isSubmitting?: boolean;
  isValid?: boolean;
  icon?: any;
  variant?: ButtonVariant;
  isUpdate?: boolean;
  onClick?: Function | any;
};
const FormButton: FC<FormButtonProps> = ({
  type,
  className,
  text,
  isSubmitting,
  isValid,
  icon,
  isUpdate,
  onClick,
}) => {
  return (
    <Fragment>
      <Button
        variant="primary"
        type={type}
        disabled={isSubmitting || !isValid}
        className={clsx((isSubmitting || !isValid) && '', className)}
        onClick={onClick}
      >
        {!isSubmitting ? (
          <span>
            {text} {icon && icon}
          </span>
        ) : (
          <Fragment>
            <span
              className="spinner-grow spinner-grow-sm"
              aria-hidden="true"
            ></span>
          </Fragment>
        )}
      </Button>
    </Fragment>
  );
};

export { FormButton };
