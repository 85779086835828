import { FC, Fragment, useState } from 'react';
import {
  CloseButton,
  Col,
  FormGroup,
  Modal,
  ModalProps,
  Row,
} from 'react-bootstrap';
import Form from 'react-bootstrap/esm/Form';
import { FormButton } from '../../FormButton';
import { useFormik } from 'formik';
import { boolean, number, object, string } from 'yup';
import { axiosPost } from '../../../helpers/RequestHelper';

const CreatePropertyCategoryModal: FC<ModalProps> = ({ show, onHide }) => {
  const initialValues = {
    name: '',
    status: '',
    description: '',
  };
  const validationSchema = object({
    name: string().required('Category name is required.'),
    status: number().required('Category status is required.'),
    description: string().required('Category description is required.'),
  });
  const {
    getFieldProps,
    isSubmitting,
    touched,
    errors,
    handleSubmit,
    isValid,
    values,
  } = useFormik({
    initialValues,
    // isInitialValid: true,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      try {
        const req = await axiosPost('/property/category/create', {
          name: values.name,
          status: values.status,
          description: values.description,
        });
        if (req?.success) {
          resetForm();
        }
      } catch (e) {
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <Fragment>
      <Modal size="lg" centered show={show} onHide={onHide}>
        <Modal.Header className="d-flex align-baseline">
          <h4>Create Category - {values?.name}</h4>{' '}
          <CloseButton onClick={onHide} />
        </Modal.Header>
        <Modal.Body className="p-3">
          <form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={!!errors.name}
                    placeholder="Enter name"
                    {...getFieldProps('name')}
                    name="name"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    {...getFieldProps('status')}
                    name="status"
                    isInvalid={!!errors.status}
                  >
                    <option>Select Category</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.status}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={12}>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    {...getFieldProps('description')}
                    name="description"
                    isInvalid={!!errors.description}
                    rows={6}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={4}>
                <FormButton
                  type="submit"
                  isSubmitting={isSubmitting}
                  isValid={isValid}
                  text="Save Changes"
                />
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
export { CreatePropertyCategoryModal };
