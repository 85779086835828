import { FC, Fragment, useEffect, useState } from 'react';
import { CloseButton, Col, Modal, ModalProps, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { FormButton } from '../../FormButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { axiosGet, axiosPost, axiosPut } from '../../../helpers/RequestHelper';

interface LocationModalProps extends ModalProps {
  data?: any;
}

const LocationModal: FC<LocationModalProps> = ({ show, onHide, data }) => {
  const [state, setState] = useState<any[]>([]);
  const [country, setCountry] = useState<any[]>([]);
  const [locationType, setLocationType] = useState<string>('');

  const validationSchemas: any = {
    city: yup.object({
      city: yup.string().required('City is required.'),
      neighborhood: yup.string().required('Neighborhood is required.'),
      stateId: yup.string().required('State is required.'),
    }),
    state: yup.object({
      state: yup.string().required('State is required.'),
    }),
  };

  const initialValues = {
    city: data?.city || '',
    neighborhood: data?.neighborhood || '',
    status: data?.status || '',
    state: data?.state?.name || '', // Using state name for input field
    stateId: data?.state?.id || '', // Using state ID for select field
    country: data?.country || '',
    countryId: data?.country?.id || '',
    locationType: data?.city ? 'city' : 'state',
  };

  const {
    getFieldProps,
    isSubmitting,
    touched,
    errors,
    handleSubmit,
    isValid,
    values,
    setValues,
    setErrors,
    setTouched,
    validateForm,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchemas[locationType] || validationSchemas.city,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      try {
        let payload;

        if (locationType === 'city') {
          // Only include fields relevant to "city" in the payload
          payload = {
            city: values.city,
            neighborhood: values.neighborhood,
            state: values.stateId, // Assuming this is the state ID
            country: values.country?.id,
            status: values.status,
          };
          // Ensure no empty payload is submitted
          if (payload && Object.keys(payload).length > 0) {
            let req;
            if (data?.id) {
              req = await axiosPut(`/property/places/list/${data.id}`, payload);
            } else {
              req = await axiosPost('/property/places/create', payload);
            }
            if (req?.success) {
              resetForm();
              onHide && onHide();
            }
          } else {
            console.warn('Empty payload, nothing to submit.');
          }
        } else if (locationType === 'state') {
          // Only include fields relevant to "state" in the payload
          payload = {
            name: values.state,
            country: values.country,
          };

          // Optionally, create a new state via API call and update the payload
          const stateResponse = await axiosPost(
            '/property/state/create',
            payload
          );
          if (stateResponse?.success) {
            resetForm();
            onHide && onHide();
          } else {
            throw new Error('Failed to create state.');
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    (async () => {
      try {
        const stateRes = await axiosGet('/property/state/list');
        if (stateRes?.success) {
          const sortedStates = stateRes.state.sort((a: any, b: any) =>
            a.name.localeCompare(b.name)
          );
          setState(sortedStates);
        }
        const countryRes = await axiosGet('/property/country/list');
        if (countryRes?.success) {
          const sortedCountries = countryRes.countries.sort((a: any, b: any) =>
            a.name.localeCompare(b.name)
          );
          setCountry(sortedCountries);
        }
      } catch (e) {
        console.error(e);
      }
    })();

    if (data) {
      setValues({
        ...data,
        state: data.state?.name || '',
        stateId: data.state?.id || '',
        locationType: data.city ? 'city' : 'state',
      });
    }
  }, [data, setValues]);

  useEffect(() => {
    // Validate form when locationType changes
    validateForm();
  }, [locationType, validateForm]);

  return (
    <Fragment>
      <Modal size="lg" centered show={show} onHide={onHide}>
        <Modal.Header className="d-flex align-baseline">
          <h4>
            {data
              ? `Edit Location - ${values?.city || values?.state}`
              : 'Create Location'}
          </h4>
          <CloseButton onClick={onHide} />
        </Modal.Header>
        <Modal.Body className="p-3">
          <form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md={12}>
                <Form.Group>
                  <Form.Label>Choose Location Type</Form.Label>
                  <Form.Select
                    value={locationType}
                    onChange={(e) => setLocationType(e.target.value)}
                  >
                    <option value="" disabled>
                      Select Location Type
                    </option>
                    <option value="city">City & Neighborhood</option>
                    <option value="state">State</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            {locationType === 'city' && (
              <div>
                <Row className="mb-3">
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        isInvalid={!!errors.city && !!touched.city}
                        placeholder="Enter city name"
                        {...getFieldProps('city')}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.city as string}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Neighborhood</Form.Label>
                      <Form.Control
                        type="text"
                        isInvalid={
                          !!errors.neighborhood && !!touched.neighborhood
                        }
                        placeholder="Enter neighborhood name"
                        {...getFieldProps('neighborhood')}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.neighborhood as string}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>State</Form.Label>
                      <Form.Select
                        {...getFieldProps('stateId')}
                        isInvalid={!!errors.stateId && !!touched.stateId}
                      >
                        <option>Select State</option>
                        {state.map((item: any, i: number) => (
                          <option value={item.id} key={i}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.stateId as string}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            )}
            {locationType === 'state' && (
              <div>
                <Row className="mb-3">
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        type="text"
                        isInvalid={!!errors.state && !!touched.state}
                        placeholder="Enter state name"
                        {...getFieldProps('state')}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.state as string}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            )}
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Country</Form.Label>
                  <Form.Select
                    {...getFieldProps('country')}
                    isInvalid={!!errors.country && !!touched.country}
                  >
                    <option>Select Country</option>
                    {country.map((item: any, i: number) => (
                      <option value={item.id} key={i}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.country as string}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    {...getFieldProps('status')}
                    isInvalid={!!errors.status && !!touched.status}
                  >
                    <option>Select Status</option>
                    <option value={1}>Active</option>
                    <option value={0}>Inactive</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.status as string}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={4}>
                <FormButton
                  type="submit"
                  isSubmitting={isSubmitting}
                  isValid={isValid}
                  text={data ? 'Save Changes' : 'Create Location'}
                />
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export { LocationModal };
