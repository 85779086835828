import React, { Fragment } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const ViewCoupons = () => {
  return (
    <Fragment>
      <Row>
        <Col md={8}>
          <Card>
            <Card.Header>
              <h3>Coupons</h3>
              <small className="text-muted">
                Add and Manage property coupons.
              </small>
            </Card.Header>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export { ViewCoupons };
