import { Fragment } from 'react';
import { AppLayout } from '../modules/layout/AppLayout';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from '../modules/layout/ThemeProvider';

const AppLanding = () => {
  return (
    <Fragment>
      <ThemeProvider>
        <AppLayout>
          <Outlet />
        </AppLayout>
      </ThemeProvider>
    </Fragment>
  );
};
export { AppLanding };
