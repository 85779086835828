import { Fragment } from "react";
import { PageNav, PageTabItem } from "../../modules/layout/menu/PageTabItem";
import { Nav } from "react-bootstrap";
import plusIcon from "../../assets/icons/hmicons/plusIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
const ReservationNav = () => {
  const navigate = useNavigate();
  // Define the array of items with page titles and URLs
  const pageItems: Array<[string, string]> = [
    ["All Bookings", "/reservation/list"],
    ["Upcoming", "/reservation/list?status=upcoming"],
    ["Ongoing", "/reservation/list?status=ongoing"],
    ["Completed", "/reservation/list?status=completed"],
    ["Source", "/reservation/source"],
  ];

  const { pathname } = useLocation();

  console.log(pathname);
  return (
    <Fragment>
      <PageNav
        items={pageItems}
        action
        actionProps={{
          type: "button",
          buttonText:
            pathname === "/reservation/list"
              ? "Create Reservation"
              : "Create Source",
          buttonIcon: plusIcon,
          buttonAction: () => {
            pathname === "/reservation/list"
              ? navigate("/reservation/create")
              : navigate("/reservation/create");
          },
        }}
      />
    </Fragment>
  );
};
export { ReservationNav };
