import React, {
  createContext,
  useContext,
  useState,
  FC,
  ReactNode,
} from 'react';

// Define a ThemeContext
const ThemeContext = createContext<any | null>(null);

// Define the props for ThemeProvider, including children
interface ThemeProviderProps {
  children: ReactNode; // This ensures children is expected
}

// Create a provider component
export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState('light'); // Default theme is light

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light'); // Toggle between light and dark theme
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

// Custom hook to use the ThemeContext
export const useTheme = () => useContext(ThemeContext);
