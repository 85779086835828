import {
  TransactionInitials,
  TransactionModel,
} from './transsaction.interface';
import { PropertyModel } from './property.interface';
import { UserIntials, UserModel } from './user.interface';

export enum ReservationStatus {
  FAILED = 0,
  PENDING = 2,
  COMPLETED = 3,
  CONFIRMED = 1,
  ONGOING = 4,
}

export enum PaymentStatus {
  FAILED = 0,
  PENDING = 2,
  PAID = 1,
}

export type ReservationModel = {
  id: string;
  code: string;
  status: number;
  created: Date | string;
  updated: Date | string;
  transaction: TransactionModel;
  occupancy: OccupancyModel;
  property: PropertyModel;
  user: UserModel;
  log: LogModel;
  source: SourceModel; // Add based on the cutoff object structure
};

export type OccupancyModel = {
  id: string;
  max_occupancy: number;
  adult: number;
  children: number;
  pet: number;
  bedroom: number | null;
  bathroom: number | null;
  bed: number | null;
  created: string;
  updated: string;
};
export type LogModel = {
  id: string;
  checkin: string;
  checkout: string;
  external_id: string | null;
  external_summary: string | null;
  description: string | null;
  confirmed: boolean;
  status: boolean;
  created: string;
  updated: string;
};

export type SourceModel = {
  id: string; // Unique identifier
  name: string; // Name of the source
  description: string; // Description of the source
  status: number; // Status (e.g., active/inactive)
  created: string; // Timestamp when the source was created
  updated: string; // Timestamp when the source was last updated
};

export const ReservationIntials: ReservationModel = {
  id: '',
  code: '',
  status: 0,
  created: '',
  updated: '',
  transaction: {
    ...TransactionInitials,
  }, // Initialize as an empty array
  occupancy: {
    id: '',
    max_occupancy: 0,
    adult: 0,
    children: 0,
    pet: 0,
    bedroom: null,
    bathroom: null,
    bed: null,
    created: '',
    updated: '',
  },
  property: {
    id: '',
    name: '',
    internal_name: '',
    description: '',
    slug: '',
    average_rating: 0,
    available: 0,
    availability_date: '',
    status: 0,
    created: '',
    updated: '',
    location: {
      id: '',
      address: '',
      address_two: '',
      apartment_number: '',
      zipcode: 0,
      longitude: '',
      latitude: '',
      created: '',
      updated: '',
      place: {
        id: '',
        city: '',
        neighborhood: null,
        status: 0,
        created: '',
        updated: '',
      },
    },
    pictures: [], // Initialize as an empty array
    fee: {
      id: '',
      service_charge: 0,
      caution_charge: 0,
      price: 0,
      created: '',
      updated: '',
    },
    reviews: [], // Initialize as an empty array
    reservation: [],
  },

  user: {
    ...UserIntials,
  },
  log: {
    id: '',
    checkin: '',
    checkout: '',
    external_id: null,
    external_summary: null,
    description: null,
    confirmed: false,
    status: false,
    created: '',
    updated: '',
  },
  source: {
    id: '',
    name: '',
    description: '',
    status: 0,
    created: '',
    updated: '',
  },
};
