import {
  FC,
  Fragment,
  ReactNode,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import DataTable, {
  createTheme,
  TableStyles,
} from "react-data-table-component";
import { Button, ButtonGroup, Card, Dropdown, Modal } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HomelyIcon, SectionLoader } from "../../helpers/Utils";
import filterIcon from "../../../assets/icons/hmicons/fiterIcon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import { axiosPost } from "../../helpers/RequestHelper";
import { Notify } from "../../helpers/NotificationHelper";
import { FaFileUpload } from "@react-icons/all-files/fa/FaFileUpload";
import { FaCloudUploadAlt } from "@react-icons/all-files/fa/FaCloudUploadAlt";
type tableProps = {
  data: any[];
  columns: any[];
  allowClick?: boolean;
  headerTitle?: any;
  filters?: any;
};

const tableStyles: TableStyles = {
  table: {
    style: {
      fontFamily: "inherit",
    },
  },
  responsiveWrapper: {
    style: {
      overflow: "visible",
      borderRadius: "40px",
    },
  },
  header: {
    style: {
      fontSize: "16px",
      marginBottom: "5px",
      padding: "2px",
    },
  },
  head: {
    style: {
      fontSize: "14px",
      fontWeight: 600,
    },
  },
  rows: {
    style: {
      minHeight: "72px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      textTransform: "capitalize",
      textAlign: "left",
      padding: "20px",
      fontWeight: 400,
      fontSize: "13px",
    },
  },
  pagination: {
    style: {
      fontSize: "13px",
      minHeight: "56px",
    },
    pageButtonsStyle: {
      borderRadius: "10px",
      height: "40px",
      width: "40px",
      padding: "8px",
      margin: "5px",
      cursor: "pointer",
      transition: "0.4s",
      color: "green",
      fill: "#ffffff",
      border: "1px solid var(--hm-primary-green)",
      backgroundColor: "var(--hm-primary-green)",
      "&:disabled": {
        cursor: "unset",
        color: "#ffffff",
        fill: "#ffffff",
      },
      "&:hover:not(:disabled)": {
        backgroundColor: "#333",
      },
      "&:focus": {
        outline: "none",
        backgroundColor: "#333",
      },
    },
  },
};

createTheme(
  "solarized",
  {
    text: {
      primary: "#111827",
      secondary: "#6B7280",
      fontFamily: "Poppins, sans-serif",
      fontSize: "12px",
      fontWeight: "400",
      hover: "#FF9900",
    },
    background: {
      default: "#ffffff",
      hover: "#FF9900",
    },
    context: {
      background: "#BD9241",
      text: "#FFFFFF",
    },
    divider: {
      default: "#E5E7EB",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "#FF9900",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "light",
);

const CustomPagination: FC<any> = ({
  currentPage,
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage,
}) => {
  const totalPages = Math.ceil(rowCount / rowsPerPage);

  const handlePrevious = () => {
    if (currentPage > 1) {
      onChangePage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onChangePage(currentPage + 1);
    }
  };

  return (
    <div className="custom-pagination">
      <button onClick={handlePrevious} disabled={currentPage === 1}>
        Previous
      </button>

      <span>
        Page {currentPage} of {totalPages}
      </span>

      <button onClick={handleNext} disabled={currentPage === totalPages}>
        Next
      </button>

      <select
        value={rowsPerPage}
        onChange={(e) => onChangeRowsPerPage(Number(e.target.value))}
      >
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={20}>20</option>
      </select>
    </div>
  );
};
const Table: FC<tableProps> = ({
  data,
  columns,
  allowClick = false,
  headerTitle,
  filters,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [pending, setPending] = useState(true);
  const [column, setColumn] = useState<any[]>([]);
  const [isData, setIsData] = useState(data);
  const fileSelectorRef = useRef<HTMLInputElement>(null);

  const [partial, setPartial] = useState({
    imported: false,
  });
  // Open file selector
  const openFileSelector = () => {
    if (fileSelectorRef.current) {
      fileSelectorRef.current.click();
    }
  };
  const handleFileSelection = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      try {
        const reader = new FileReader();
        reader.onload = async (event) => {
          const data = event.target?.result;
          if (data) {
            const workbook = XLSX.read(data, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            // Convert to JSON with 'raw: true' to keep the original data as is
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: true });

            // Utility function to convert Excel date serials to ISO format
            const convertExcelDateToISO = (excelDate: any): string => {
              if (
                typeof excelDate === "number" &&
                !isNaN(excelDate) &&
                excelDate > 0
              ) {
                const jsDate = new Date((excelDate - 25569) * 86400 * 1000);
                if (!isNaN(jsDate.getTime())) {
                  return jsDate.toISOString().split("T")[0];
                }
              }
              return excelDate ? excelDate.toString().trim() : "";
            };
            const currencyRegex = /[$€£₦¥]/;

            // Process the data
            const formattedData = jsonData
              .map((data: any) => {
                const totalAmount = data["Total amount paid by guest"] || ""; // Default to an empty string if undefined
                const totalAmountString = String(totalAmount); // Ensure it's a string
                const currencyMatch = totalAmountString.match(currencyRegex);
                const currency = currencyMatch ? currencyMatch[0] : "ngn"; // Fallback to "N/A" if no currency is found

                // Extract numeric amount by stripping out non-numeric characters
                const numericAmount = parseFloat(
                  totalAmountString.replace(/[^0-9.]/g, "") || "0",
                );
                // Categorized User Data
                const userData = {
                  email: data["Guest email address"] || "",
                  firstname: data["Name of guest"]?.split(" ")[0] || "",
                  lastname: data["Name of guest"]?.split(" ")[1] || "",
                  middlename: data["Name of guest"]?.split(" ")[2] || "",
                  phone: data["Guest contact number"] || "",
                };

                // Categorized Reservation Data
                const reservationData = {
                  created_at: convertExcelDateToISO(data["Date of entry"]),
                  property: data["Property"] || "",
                  // pricingPlan: data["Pricing Plan"] || "",
                  source: data["Source"] || "",
                  checkin: convertExcelDateToISO(data["Check-in Date"]),
                  checkout: convertExcelDateToISO(data["Check-out Date"]),
                  status: data["Booking Status"] || "",
                  // checkInFormNumber: data["Check-in form number"] || "",
                  // checkOutFormNumber: data["Check-out form number"] || "",
                  price: data["Price per night"] || 0,
                  nights_booked: data["No of nights"] || 0,
                  total_fee: numericAmount || 0,
                  currency, // Currency extracted using regex
                  // fxRate: data["FX rate"] || 0,
                  caution_fee: data["Caution Fee"] || 0,
                  service_fee: data["Homely fee"] || 0,
                  // partnerPayout: data["Partner payout"] || 0,
                };

                // Categorized Notes & Feedback
                const feedbackData = {
                  review: data["Notes"] || "",
                  overallExperience:
                    data[
                      "How would you rate your overall experience with Homely?"
                    ] || "",
                  referralLikelihood:
                    data["How likely are you refer Homely?"] || "",
                };

                // Categorized Government Data
                const kyc = {
                  id_type: data["Government issued ID"] || "",
                  id_card: data["Upload government ID"] || "",
                };

                return {
                  userData,
                  reservationData,
                  feedbackData,
                  kyc,
                };
              })
              .filter((item) => item.reservationData.property !== ""); // Filter out items with an empty 'property'

            console.log("Formatted Data:", formattedData);

            // Send the entire array to the backend in a single request
            const response = await axiosPost(
              "/reservation/advanced/bulk-create",
              {
                reservations: formattedData,
              },
            );

            console.log("Submission Complete", response);

            if (response?.success) {
              Notify({
                type: "toastr",
                toastrProps: { type: "success", text: response?.message },
              });
            } else {
              Notify({
                type: "toastr",
                toastrProps: { type: "error", text: response?.message },
              });
            }
          }
        };
        reader.readAsBinaryString(file);
      } catch (error) {
        console.error("Error processing file:", error);
      } finally {
        window.location.reload();
      }
    }
  };

  // Handle file selection
  // const handleFileSelection = async (
  //   e: React.ChangeEvent<HTMLInputElement>,
  // ) => {
  //   if (e.target.files && e.target.files[0]) {
  //     const file = e.target.files[0];
  //
  //     try {
  //       const reader = new FileReader();
  //       reader.onload = async (event) => {
  //         const data = event.target?.result;
  //         if (data) {
  //           const workbook = XLSX.read(data, { type: "binary" });
  //           const sheetName = workbook.SheetNames[0];
  //           const worksheet = workbook.Sheets[sheetName];
  //
  //           // Convert to JSON with 'raw: true' to keep the original data as is
  //           const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
  //
  //           // List of columns that should be treated as dates
  //           const dateColumns = [
  //             "Date of entry",
  //             "Check-in Date",
  //             "Check-out Date",
  //           ];
  //
  //           // Utility function to convert Excel date serials to ISO format
  //           const convertExcelDateToISO = (excelDate: any): string => {
  //             if (
  //               typeof excelDate === "number" &&
  //               !isNaN(excelDate) &&
  //               excelDate > 0
  //             ) {
  //               const jsDate = new Date((excelDate - 25569) * 86400 * 1000); // Convert Excel serial date to JS date
  //               if (!isNaN(jsDate.getTime())) {
  //                 // Check if it's a valid date
  //                 return jsDate.toISOString().split("T")[0]; // Return the ISO date string (YYYY-MM-DD)
  //               }
  //             }
  //             // If it's not a valid Excel date, return it as a string or empty string if undefined
  //             return excelDate ? excelDate.toString().trim() : "";
  //           };
  //
  //           // Process the data
  //           const formattedData = jsonData
  //             .map((item: any) => {
  //               const {
  //                 Property: property,
  //                 Source: source,
  //                 "Price per night": pricePerNight,
  //                 "Guest contact number": phone,
  //                 "Total amount paid by guest": totalAmount,
  //                 "Name of guest": name,
  //                 "Date of entry": created_at,
  //                 "Check-out Date": checkOut,
  //                 "Check-in Date": checkIn,
  //                 ...rest
  //               } = item;
  //
  //               // Split name into first name and last name
  //               const [firstName, lastName] = name ? name.split(" ") : ["", ""];
  //
  //               // Process each column (date handling, etc.)
  //               const formattedItem = Object.entries(rest).reduce(
  //                 (acc: any, [key, value]) => {
  //                   if (dateColumns.includes(key)) {
  //                     acc[key] = convertExcelDateToISO(value); // Convert dates
  //                   } else {
  //                     acc[key] = value; // Keep other columns as they are
  //                   }
  //                   return acc;
  //                 },
  //                 {},
  //               );
  //
  //               // Ensure checkIn and checkOut are formatted correctly
  //               const formattedCheckIn = convertExcelDateToISO(checkIn);
  //               const formattedCheckOut = convertExcelDateToISO(checkOut);
  //               const formattedCreatedAt = convertExcelDateToISO(created_at);
  //
  //               return {
  //                 ...formattedItem, // Include other keys
  //                 property:
  //                   property && isNaN(Number(property))
  //                     ? property.trim().replace(/\s+/g, "_").toLowerCase()
  //                     : "",
  //                 source: source || "",
  //                 price: pricePerNight || "",
  //                 phone: phone || "",
  //                 totalAmount: totalAmount || "",
  //                 firstName: firstName || "",
  //                 lastName: lastName || "",
  //                 created_at: formattedCreatedAt || "",
  //                 checkIn: formattedCheckIn, // Formatted checkIn
  //                 checkOut: formattedCheckOut, // Formatted checkOut
  //               };
  //             })
  //             .filter((item) => item.property !== ""); // Filter out items with an empty 'property'
  //
  //           console.log("Formatted Data:", formattedData);
  //
  //           // Assuming you want to submit each item of formattedData to the backend
  //           const response: any = await Promise.all(
  //             formattedData.map(async (data) => {
  //               const userData = {
  //                 email: data.email || "", // substitute email if available, else fallback to values
  //                 firstname: data.firstName || "",
  //                 lastname: data.lastName || "",
  //                 middlename: data.middleName || "",
  //                 phone: data.phone || "",
  //                 fax: "",
  //               };
  //
  //               const propertyData = {
  //                 source: data.source || "",
  //                 id: data.property || "",
  //                 status: "1",
  //                 created_at: data.created_at,
  //               };
  //
  //               const guestData = {
  //                 adult: data.adults || 1,
  //                 children: data.children || 0,
  //                 pet: parseInt(data.pet) || 0,
  //                 checkin: data.checkIn || "",
  //                 checkout: data.checkOut || "",
  //               };
  //
  //               const paymentData = {
  //                 currency: data.currency || "ngn",
  //                 paymentMethod: data.paymentMethod || "card",
  //                 paymentStatus: data.paymentStatus || "Paid",
  //                 price: data.price || 0,
  //                 discount: data.discount || 0,
  //                 service_fee: data.serviceCharge || 0,
  //                 caution_fee: data.cautionFee || 0,
  //               };
  //
  //               // Send individual reservation data to backend
  //               return await axiosPost("/reservation/advanced/create", {
  //                 user: userData,
  //                 property: propertyData,
  //                 guest: guestData,
  //                 payment: paymentData,
  //               });
  //             }),
  //           );
  //
  //           console.log("Submission Complete", response);
  //
  //           if (response?.success) {
  //             Notify({
  //               type: "toastr",
  //               toastrProps: { type: "success", text: response?.message },
  //             });
  //           } else {
  //             Notify({
  //               type: "toastr",
  //               toastrProps: { type: "error", text: response?.message },
  //             });
  //           }
  //         }
  //       };
  //       reader.readAsBinaryString(file);
  //     } catch (error) {
  //       console.error("Error processing file:", error);
  //     } finally {
  //     }
  //   }
  // };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setColumn(columns);
      setIsData(data);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [columns, data, partial.imported]);

  const filename = (ext: string) => {
    const date = new Date().getTime();
    const sanitizeHeader = headerTitle
      .toLowerCase() // Convert to lowercase
      .trim() // Remove leading and trailing whitespace
      .replace(/\s+/g, "-") // Replace spaces with dashes
      .replace(/[^a-z0-9\-]/g, "");
    return `${sanitizeHeader}-${date}.${ext}`;
  };

  // function convertArrayOfObjectsToCSV(array: any) {
  //   let result: any;
  //
  //   const columnDelimiter = ",";
  //   const lineDelimiter = "\n";
  //   const keys = Object.keys(isData[0]);
  //
  //   result = "";
  //   result += keys.join(columnDelimiter);
  //   result += lineDelimiter;
  //
  //   array.forEach((item: any) => {
  //     let ctr = 0;
  //     keys.forEach((key: any) => {
  //       if (ctr > 0) result += columnDelimiter;
  //
  //       result += item[key];
  //
  //       ctr++;
  //     });
  //     result += lineDelimiter;
  //   });
  //
  //   return result;
  // }

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function downloadCSV(dataArray: any[]) {
    const reservations = dataArray.map((data: any) => ({
      reservation_id: data?.id,
      code: data?.code,
      checkin_date: data?.log?.checkin,
      checkout_date: data?.log?.checkout,
      nights_booked: data?.log?.nights_booked,
      property_name: data?.property?.name,
      property_address: data?.property?.location?.address,
      property_city: data?.property?.location?.place?.city,
      max_occupancy: data?.occupancy?.max_occupancy,
      adult_count: data?.occupancy?.adult,
      children_count: data?.occupancy?.children,
      pet_count: data?.occupancy?.pet,
      source_name: data?.source?.name,
      reservation_status: data?.log?.confirmed ? "Confirmed" : "Pending",
      total_price: data?.property?.fee?.price,
      service_charge: data?.property?.fee?.service_charge,
      caution_charge: data?.property?.fee?.caution_charge,
    }));

    const csv = convertArrayOfObjectsToCSV(reservations);
    if (!csv) return;

    const link = document.createElement("a");
    const encodedUri = `data:text/csv;charset=utf-8,${csv}`;
    link.setAttribute("href", encodeURI(encodedUri));
    link.setAttribute("download", "reservations.csv");
    link.click();
  }

  function convertArrayOfObjectsToCSV(array: any[]) {
    const headers = Object.keys(array[0]).join(",");
    const rows = array
      .map((obj) =>
        Object.values(obj)
          .map((value) => `"${value || ""}"`)
          .join(","),
      )
      .join("\n");

    return `${headers}\n${rows}`;
  }

  // const exportTableToPDF = () => {
  //   const doc = new jsPDF();
  //
  //   // Prepare the column headers and data rows
  //   const tableColumnHeaders = column.map((col: any) => col.name);
  //   const tableRows = isData.map((row: any) =>
  //     column.map((col: any) => row[col.selector]),
  //   );
  //
  //   // Add a title to the PDF
  //   doc.text(headerTitle, 14, 10);
  //
  //   // Generate the table using autoTable
  //   autoTable(doc, {
  //     head: [tableColumnHeaders],
  //     body: tableRows,
  //     startY: 20, // Position the table
  //     theme: "grid", // Optional: Choose a theme
  //     headStyles: { fillColor: [22, 160, 133] }, // Optional: Customize header colors
  //   });
  //
  //   // Save the generated PDF
  //   doc.save(filename("pdf"));
  // };
  const handleClick = (e: any) => {
    const currentLocation = pathname.split(`/`).reverse()[0];
    console.log(e, currentLocation);
    navigate(`/${currentLocation}/${e.loginId}`);
  };

  const Header = () => {
    const [showFilter, setShowFilter] = useState(false);

    return (
      <Fragment>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="fw-bold text-capitalize flex-end">{headerTitle}</div>
          <div className="d-flex gap-2" role="toolbar">
            <Button
              size="sm"
              className="d-flex align-items-center px-3"
              variant="outline-primary"
              onClick={() => setShowFilter(true)}
            >
              Filter
              <HomelyIcon src={filterIcon} size={26} />
            </Button>
            {filters && filters(showFilter, setShowFilter)}

            <Dropdown
              style={{ fontSize: "12px" }}
              className="position-relative"
            >
              <Dropdown.Toggle
                as={Button}
                variant="outline-primary"
                className=""
              >
                Export <FontAwesomeIcon icon={faAngleDown} />
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{ fontSize: "12px", zIndex: 100000000 }}
                className=""
              >
                {/*<Dropdown.Item*/}
                {/*  as={Button}*/}
                {/*  className="border border-0"*/}
                {/*  onClick={() => exportTableToPDF()}*/}
                {/*>*/}
                {/*  PDF*/}
                {/*</Dropdown.Item>*/}
                <Dropdown.Item
                  as={Button}
                  className="border border-0"
                  onClick={() => downloadCSV(isData)}
                >
                  CSV
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              style={{ fontSize: "12px" }}
              className="position-relative"
            >
              <Dropdown.Toggle
                as={Button}
                variant="outline-primary"
                className=""
              >
                Import <FontAwesomeIcon icon={faAngleDown} />
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{ fontSize: "12px", zIndex: 100000000 }}
                className=""
              >
                <Dropdown.Item
                  as={Button}
                  className="  border-dotted py-4 w-25 text-center"
                  onClick={openFileSelector}
                >
                  <FaCloudUploadAlt size={40} /> Upload excel
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <input
              type="file"
              ref={fileSelectorRef}
              style={{ display: "none" }}
              accept=".xlsx, .xls"
              onChange={handleFileSelection}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Card border="light" className="">
        <Card.Body>
          <Header />
          <DataTable
            columns={column}
            data={isData}
            theme="solarized"
            pagination
            onRowClicked={(e) => {
              if (allowClick) handleClick(e);
            }}
            responsive={true}
            onSelectedRowsChange={handleClick}
            progressPending={pending}
            highlightOnHover
            pointerOnHover
            progressComponent={<SectionLoader />}
            // actions={<Header />}
            customStyles={tableStyles}
          />
        </Card.Body>
      </Card>
    </Fragment>
  );
};
export { Table };
