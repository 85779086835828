import React, { Fragment, useState } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { ReservationNav } from './ReservationNav';
import { ReservationList } from './components/ReservationList';
import { ReservationSource } from './components/ReservationSource';
import { ReservationView } from './components/ReservationView';
import {
  ReservationModify,
  ReservationModifyNew,
  ReservationSuccess,
} from './components/ReservationCreate';
import NotFound from '../NotFound';
import { usePageContext } from '../../modules/layout/context/PageContext';

const ReservationPage = () => {
  const { layoutType } = usePageContext();
  return (
    <Fragment>
      <Routes>
        <Route
          element={
            <Fragment>
              {layoutType === 'regular' && <ReservationNav />}
              <Outlet />
            </Fragment>
          }
        >
          <Route path="list" element={<ReservationList />} />
          <Route path="view/:id" element={<ReservationView />} />
          <Route path="create" element={<ReservationModifyNew />} />
          <Route path="edit/:id" element={<ReservationModify />} />
          <Route path="success/:id" element={<ReservationSuccess />} />
          <Route path="source" element={<ReservationSource />} />
          <Route path="*" element={<NotFound />} />
          <Route element={<Navigate to="/reservation/list" />} />
        </Route>
      </Routes>
    </Fragment>
  );
};
export { ReservationPage };
