import React from 'react';
import { Col, Row, Card, Image, Button, Container } from 'react-bootstrap';

export default () => {
  return (
    <main>
      <section
        className="d-flex align-items-center justify-content-center"
        style={{
          height: '80vh',
        }}
      >
        <Container>
          <Row>
            <Col
              xs={12}
              className="text-center d-flex align-items-center justify-content-center"
            >
              <div>
                <h1 className="text-primary">
                  Page not <span className="fw-bolder">found</span>
                </h1>
                <p className="lead my-4">
                  Oops! Looks like you followed a bad link. If you think this is
                  a problem with us, please tell us.
                </p>
                <Button variant="secondary" className="btn-lg" href="/overview">
                  Go back home
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
