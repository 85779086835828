import { Fragment, useState } from 'react';
import { SideMenuItem } from './SideMenuItem';
import { AssetHelper } from '../../helpers/AssetHelper';
import {
  faAngleDown,
  faArrowDown,
  faBook,
  faBuilding,
  faCalendar,
  faChartPie,
  faDashboard,
  faExchange,
  faGear,
  faImage,
  faImagePortrait,
  faPercent,
  faQuestionCircle,
  faStar,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { MediaCore } from '../../../pages/media/core/MediaCore';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  const [show, setShow] = useState('0');
  const [showMedia, setShowMedia] = useState(false);
  const handleToggle = () => {
    setShow('280px');
  };
  return (
    <Fragment>
      <div className="z-5">
        <Link
          to="/"
          className="d-flex align-items-start bg-white position-sticky sticky-top w-100 clearfix top-0 py-3 justify-content-start mb-4 link-body-emphasis text-decoration-none"
        >
          <img
            src={AssetHelper('/assets/img/brand/logo.svg')}
            alt="logo"
            style={{
              width: '100px',
            }}
          />
        </Link>
        <ul className="nav nav-pills flex-column mb-auto  w-100 ">
          <SideMenuItem to="/overview" title="Overview" icon={faChartPie} />
          <SideMenuItem to="/listing/all" title="Listing" icon={faBuilding} />
          <SideMenuItem
            to="/reservation/list"
            title="Reservation"
            icon={faBook}
          />
          <SideMenuItem to="/calendar" title="Calendar" icon={faCalendar} />
          <SideMenuItem to="/coupons" title="Coupons" icon={faPercent} />
          <SideMenuItem
            to="#"
            action={() => setShowMedia(true)}
            title="Media"
            icon={faImage}
          />
          <SideMenuItem
            to="/transactions"
            title="Transactions"
            icon={faExchange}
          />
          <SideMenuItem to="/users" title="Users" icon={faUsers} />

          <SideMenuItem to="/reviews" title="Reviews" icon={faStar} />
          <SideMenuItem
            to="/support-ticket"
            title="Support Ticket"
            icon={faQuestionCircle}
          />

          <SideMenuItem to="/settings" title="Settings" icon={faGear} />
        </ul>
      </div>
    </Fragment>
  );
};

export { Sidebar };
